export const makeDetailExplain = {
  makeintonation: (score: number) => {
    let comment;
    if (score >= 81 && score <= 100) {
      comment = "영어가 모국어인 화자와 큰 차이 없으며 자유로운 의사소통이 가능합니다!";
    } else if (score >= 61 && score <= 80) {
      comment =
        "억양이란 말의 밀고 당기기 라는 점을 이해할 수 있는 레벨입니다. 청자의 답변에 따라 자신(화자)의 억양에도 변화를 주는 연습을 통해 원어민에 가까워질 수 있습니다. 가능한 많은 원어민과 간단한 스몰 토크를 시도해 보세요.";
    } else if (score >= 41 && score <= 60) {
      comment =
        "완전하지는 않지만 억양을 살려 문장의 리듬감을 살리는 방법에 눈을 뜬 상태입니다. 억양이란 말하기의 음률이고 짧은 말로도 효과적인 의사소통을 가능케 해줍니다. 단어가 아닌 문장 단위의 긴 리듬을 연습해 봅시다.";
    } else if (score >= 21 && score <= 40) {
      comment =
        "아직 문자 전체의 억양을 살려 자유롭게 말하는 레벨은 아니지만, 단어 차원에서는 가능할 겁니다. 억양을 살릴 수 있는 단어와 표현을 한 마디 두 마디 차근차근 늘려 보세요.";
    } else {
      comment =
        "억양이 부정확한 이유는 여러가지이지만 가장 중요한 것은 목소리의 높낮이를 조절하는 것입니다. 미국식과 영국식 억양이 다르기 때문에 습득하고 싶은 원어민의 억양을 일관적으로 따라하는 연습이 매우 효과적입니다. ";
    }
    return comment;
  },
  makeAccuracy: (score: number) => {
    let comment;
    if (score >= 81 && score <= 100) {
      comment = "영어가 모국어인 화자와 큰 차이 없으며 자유로운 의사소통이 가능합니다!";
    } else if (score >= 61 && score <= 80) {
      comment =
        "부정확한 발음이 거의 없고 의사소통도 원활합니다. 단어 사이에서 발생하는 연음과 가끔 등장하는 묵음에 더 신경 쓰면 원어민 화자에 가까워질 수 있습니다.";
    } else if (score >= 41 && score <= 60) {
      comment =
        "부정확한 발음이 약간 있지만 간단한 의사소통에는 문제가 없습니다. 음절 단위의 발음 연습을 통해 속도는 늦어도 정확성을 향상할 수 있습니다. 즉흥적인 말하기에 도전하는 것도 좋은 방법입니다.";
    } else if (score >= 21 && score <= 40) {
      comment =
        "아직은 부정확한 발음이 더 많지만 간단한 의사소통에는 문제가 없습니다. 아직은 즉흥적으로 말하기보다는 준비한 문장을 큰 목소리로 빠르게 연습하는 방법이 더 효과적입니다.";
    } else {
      comment =
        "발음이 부정확한 이유는 여러가지이지만 가장 중요한 것은 목소리의 크기입니다. 원어민의 발음을 듣고 큰 목소리로 따라하는 연습만으로 많은 발전을 체감할 수 있습니다.";
    }
    return comment;
  },
  makeAccent: (score: number) => {
    let comment;
    if (score >= 81 && score <= 100) {
      comment = "영어가 모국어인 화자와 큰 차이 없으며 자유로운 의사소통이 가능합니다!";
    } else if (score >= 61 && score <= 80) {
      comment =
        "강세만으로 말의 뉘앙스와 메시지를 살릴 수 있는 레벨입니다. 날씨나 음식 같은 간단한 주제로 적극적인 스몰 토크를 시도해보세요. 곧 원어민에 가까워질 수 있습니다.";
    } else if (score >= 41 && score <= 60) {
      comment =
        "같은 단어라도 문장 구조와 길이에 따라 강세가 변한다는 것을 알고 계실 겁니다. 단문보다는 장문 위주의 연습을 통해 이미 알고 있던 강세의 변화를 본격적으로 느끼고 연습하는 것을 추천합니다.";
    } else if (score >= 21 && score <= 40) {
      comment =
        "아직 모든 강세를 구별할 수 있는 레벨은 아니지만, 강세를 제대로 주는 것만으로 원어민과 비슷한 발음 구현이 가능합니다. 강세를 살릴 수 있는 단어와 표현을 한 마디 두 마디 차근차근 늘려 보세요.";
    } else {
      comment =
        "강세란 자신(화자)이 강조하고 싶은 부분입니다. 단어마다 강세를 주어야하는 부분이 다르기 때문에 원어민의 말을 반복적으로 들으며 어디에 강세를 두는지 확인하세요.";
    }
    return comment;
  },
  makeSpeed: (score: number) => {
    let comment;
    if (score >= 81 && score <= 100) {
      comment = "영어가 모국어인 화자와 큰 차이 없으며 자유로운 의사소통이 가능합니다!";
    } else if (score >= 61 && score <= 80) {
      comment =
        "원어민과 큰 차이가 없지만 발음의 정확성과 억양 강세 등을 자신의 말하기 속도에 조화롭게 맞추려면 실전 연습이 필요합니다. 원어민들과 적극적인 대화를 통해 균형을 맞추어 보세요. 작고 평범한 주제일수록 오히려 대화할 내용이 늘어납니다.";
    } else if (score >= 41 && score <= 60) {
      comment =
        "영어는 속도에 따라 말의 의미가 달라질 수도 있습니다. 원어민의 말하기를 따라하며 속도를 맞추어 보는 연습이 계속 유효합니다. 속도가 늘어나면서 발음 정확성이 떨어지지 않게 조심하세요.";
    } else if (score >= 21 && score <= 40) {
      comment =
        "즉흥적인 대화는 조금 힘들어도 미리 준비한 짧은 말하기는 괜찮을 겁니다. 같은 표현이라도 원어민이 말하는 속도에 맞추어 연습하는 것이 자연스럽게 실력을 상승시킬 수 있는 지름길입니다.";
    } else {
      comment =
        "아직 말하기 속도는 크게 신경 쓰지 않아도 됩니다. 다만, 원래 빠르게 말하는 것이 익숙한 영어이기 때문에 말하기 실력과 함께 자연스러운 속도 상승을 기대해 볼 수 있습니다.";
    }
    return comment;
  },
  makePause: (score: number) => {
    let comment;
    if (score >= 81 && score <= 100) {
      comment = "영어가 모국어인 화자와 큰 차이 없으며 자유로운 의사소통이 가능합니다!";
    } else if (score >= 61 && score <= 80) {
      comment =
        "음절에 따른 끊기는 물론 휴지기와 부사 처리까지 원어민 수준에 거의 도달했습니다. 이제부터는 실전입니다. 원어민과 대화하며 간단한 자기소개나 인사도 좋지만 프레젠테이션 같은 장문 위주의 글을 읽으며 끊어 읽기를 다듬어보세요";
    } else if (score >= 41 && score <= 60) {
      comment =
        "휴지기를 완전히 구현할 수는 없지만 끊어 읽음으로써 더 원활한 의사소통이 가능한 것을 경험할 수 있는 레벨입니다. 원어민의 말하기를 철저히 따라하는 연습이 아직까지는 유효합니다.";
    } else if (score >= 21 && score <= 40) {
      comment =
        "아직은 끊어 읽기가 어색하지만 음절이 짧은 단어는 발음하기에 충분합니다. 짧은 단어를 많이 반복하면서 접두어나 접미어에 따른 끊어 읽기도 자연스럽게 터득할 수 있습니다.";
    } else {
      comment =
        "음절이란 단어의 구성 단위입니다. 아직은 단어의 뜻이 더 중요하지만 영어는음절을 기준으로 발음하는 것이 기본입니다. 원어민의 발음을 느리게 듣기로 연습하면 어디서 음절이 변화하는지 쉽게 알아낼 수 있습니다.";
    }
    return comment;
  },
};
