import React from "react";
import { useRecoilValue } from "recoil";
import { recorderAtom } from "../../recoil/recorderAtom";
import { contentAtom } from "../../recoil/contentAtom";
import { mainAPI } from "../../api/axios";

const useElaSolution = () => {
  const recording = React.useRef<any>(null);
  const { recorder } = useRecoilValue(recorderAtom);
  const question = useRecoilValue(contentAtom);

  const startRecording = () => {
    recording.current = setTimeout(() => {
      if (recorder?.state !== "recording") {
        recorder?.start();
        recorder!.ondataavailable = async (e) => {
          const formdata = new FormData();
          formdata.append("times", String(question.times));
          formdata.append("question_no", String(question.question_no));
          formdata.append("num", String(question.renderNum));
          formdata.append("student_audio", e.data);
          mainAPI.getAnswer(formdata);
        };
      }
    }, 2000);
  };
  const stopRecording = () => {
    recorder?.stop();
  };
  return { recorder, startRecording, stopRecording };
};

export default useElaSolution;
