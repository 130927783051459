import { atom } from "recoil";
interface Atom {
  recorder: MediaRecorder | null;
}

export const recorderAtom = atom<Atom>({
  key: "recorder",
  default: {
    recorder: null,
  },
});
