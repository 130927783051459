import { atom } from "recoil";
interface answerList {
  text: string;
  score: number;
}
export interface Atom {
  answer: answerList[] | [];
}
export const answerAtom = atom<Atom>({
  key: "answer",
  default: {
    answer: [],
  },
});
