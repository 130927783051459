import { atom } from "recoil";
interface Atom {
  question_no: number;
  times: number;
  num: number;
  renderNum: number;
}

export const contentAtom = atom<Atom>({
  key: "content",
  default: {
    question_no: 1,
    times: 1,
    num: 1,
    renderNum: 1,
  },
});
