import styled from "styled-components";
import visual from "../../assets/img/visual.jpg";

export const StIntro = {
  Container: styled.div`
    position: relative;
    width: 100%;
  `,
  Header: styled.header`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    background: transparent;
    z-index: 100;
    transition: all 0.3s;
  `,
  HeaderContents: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    width: 100%;
    max-width: 1640px;
    margin: 0 auto;
    
    @media screen and (max-width:1200px){
      padding: 20px 36px;
    }

    @media screen and (max-width:992px){
      padding: 16px 36px;
    }

    @media screen and (max-width:768px){
      padding: 16px 32px;
    }

    @media screen and (max-width:480px){
      padding: 0px 16px;
    }

    img {
      display: block;
    }
  `,
  KakaoLogout: styled.button`
    min-width: 5.625rem; //min-width로 수정
    height: 2.75rem;
    padding: 0 1rem;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #fff;
    background-color: transparent;
    font-weight: 600;
    font-size: 0.938rem;
    color: #fff;
    letter-spacing: -0.1px;
  `,
  KakaoLogin: styled.a`
    width: 3.25rem;
    height: 3.25rem;
    display: block;

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  `,
  Logo: styled.a`
    width: 8rem;
    height: 6rem;
    display: inline-block;

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  `,
  Visual: styled.div`
    position: relative;
    width: 100%;
    height: var(--vh);
    background-image: url(${visual});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 6rem;
    font-family: "Gmarket Sans", sans-serif;
  `,
  VisualContents: styled.div`
    width: 100%;
    max-width: 1640px;
    margin: 0 auto;
  `,
  VisualTitle: styled.div`
    font-weight: 300;
    font-size: 4rem;
    line-height: 1.5;
    text-align: center;
    color: #fff;
    white-space: pre-line;
    margin-top: calc(var(--vh) * 0.1555555);
    
    @media screen and (max-width: 1440px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media screen and (max-width: 1200px) {
      padding-left: 36px;
      padding-right: 36px;
    }

    @media screen and (max-width: 768px) {
      padding-left: 32px;
      padding-right: 32px;
    }

    @media screen and (max-width: 480px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  `,
  ToeSBox: styled.div`
    padding-top: 100px;
    padding-bottom: 140px;
    
    @media screen and (max-width: 1440px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media screen and (max-width: 1200px) {
      padding-left: 36px;
      padding-right: 36px;
    }

    @media screen and (max-width: 768px) {
      padding-left: 32px;
      padding-right: 32px;
    }

    @media screen and (max-width: 480px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  `,
  Title: styled.div`
    font-weight: 800;
    font-size: 2.75rem;
    color: var(--highlight);
    text-align: center;
    margin-bottom: 4rem;
    ::after {
      display: block;
      content: "";
      width: 100px;
      height: 4px;
      border-radius: 999px;
      background: var(--highlight);
      margin: 20px auto 0;
    }
    > span {
      font-weight: 400;
    }
  `,
  ThumbBox: styled.ul`
    width: 100%;
    max-width: 1640px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    @media screen and (min-width: 1441px) {
      gap: 40px;
    }

    @media screen and (max-width: 1440px) {
      gap: 30px;
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
      gap: 24px;
    }

    @media screen and (max-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
    }
  `,
  Thumb: styled.button`
    width: 100%;
    padding-bottom: 100%;
    display: block;
    position: relative;
    border-radius: 14px;
    margin-bottom: 12px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    :hover {
      transform: scale(1.03);
    }
    > img {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      object-fit: cover;
      border-radius: 14px;
    }
  `,
  Subject: styled.p`
    width: 100%;
    padding: 5px 12px;
    border-radius: 4px;
    background: #f1e9f6;
    font-size: 1rem;
    margin-bottom: 12px;
    :last-child {
      margin-bottom: 0;
    }
  `,
  Footer: styled.footer`
    width: 100%;
    padding: 40px 0;
    border-top: 1px solid var(--border);
    line-height: 1.5;
    > address {
      display: -webkit-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      max-width: 1640px;
      margin: 0 auto;
      font-size: 0.875rem;
      color: var(--darkgray);
    }
  `,
  Copy: styled.small`
    width: 100%;
    font-weight: 300;
    font-size: 0.875rem;
    color: #9e9e9e;
    text-align: center;
  `,
};
