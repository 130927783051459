import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import useVolumeControl from "../../hooks/common/useVolumeControl";

/**
 * @description volume controller
 * 전체화면에서 컨트롤 바를 띄우기 위해 createPortal 사용
 */
const VolumeController: React.FC = () => {
  const { volume, setVolume, minusClickHandler, plusClickHandler } = useVolumeControl();

  return (
    <>
      {ReactDOM.createPortal(
        <StVolumeController.Container>
          <StVolumeController.PannelBtn onClick={minusClickHandler}>-</StVolumeController.PannelBtn>
          <StVolumeController.PannelBox>
            <StVolumeController.Pannel
              volume={volume.volume}
              pannel={0.1}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                setVolume({ volume: 0.1 });
              }}
            />
            <StVolumeController.Pannel
              volume={volume.volume}
              pannel={0.2}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                setVolume({ volume: 0.2 });
              }}
            />
            <StVolumeController.Pannel
              volume={volume.volume}
              pannel={0.3}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                setVolume({ volume: 0.3 });
              }}
            />
            <StVolumeController.Pannel
              volume={volume.volume}
              pannel={0.4}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                setVolume({ volume: 0.4 });
              }}
            />
            <StVolumeController.Pannel
              volume={volume.volume}
              pannel={0.5}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                setVolume({ volume: 0.5 });
              }}
            />
            <StVolumeController.Pannel
              volume={volume.volume}
              pannel={0.6}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                setVolume({ volume: 0.6 });
              }}
            />
            <StVolumeController.Pannel
              volume={volume.volume}
              pannel={0.7}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                setVolume({ volume: 0.7 });
              }}
            />
            <StVolumeController.Pannel
              volume={volume.volume}
              pannel={0.8}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                setVolume({ volume: 0.8 });
              }}
            />
            <StVolumeController.Pannel
              volume={volume.volume}
              pannel={0.9}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                setVolume({ volume: 0.9 });
              }}
            />
            <StVolumeController.Pannel
              volume={volume.volume}
              pannel={1}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                setVolume({ volume: 1 });
              }}
            />
          </StVolumeController.PannelBox>
          <StVolumeController.PannelBtn onClick={plusClickHandler}>+</StVolumeController.PannelBtn>
        </StVolumeController.Container>,
        document.querySelector(".fullscreen") as HTMLElement
      )}
    </>
  );
};

export default VolumeController;

interface pannelProps {
  volume: number;
  pannel: number;
}
const StVolumeController = {
  Container: styled.div`
    position: absolute;
    z-index: 140;
    width: 300px;
    height: 30px;
    border-radius: 6px;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media screen and (min-width:641px){
      top: 130px;
      right: 30px;
    }
    
    @media screen and (max-width: 640px){
      transform: rotate(90deg);
      transform-origin: top right;
      top: 98%;
      right: 90px;
    }
  `,
  PannelBox: styled.div`
    display: flex;
    align-items: center;
    border: 1px solid whitesmoke;
    overflow: hidden;
    width: 250px;
    height: 25px;
  `,
  Pannel: styled.button<pannelProps>`
    width: 25px;
    height: 25px;
    border: ${({ volume, pannel }) => (volume >= pannel ? "2px solid #ccc;" : "none")};
    background-color: ${({ volume, pannel }) => (volume >= pannel ? "#666" : "none")};
    cursor: pointer;
  `,
  PannelBtn: styled.span`
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  `,
};
