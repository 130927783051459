import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { mainAPI } from "../../../api/axios";
import { cookie } from "../../../constant/cookies";
import { viewChangerAtom } from "../../../recoil/viewChangerAtom";
import { useSetRecoilState } from "recoil";

declare global {
  interface Window {
    Kakao: any;
  }
}

const { Kakao } = window;
Kakao.init(process.env.REACT_APP_KAKAO_ID);

const KakaoSign: React.FC = (): any => {
  const redirect = process.env.REACT_APP_REDIRECT;
  const location = useLocation();
  const navigate = useNavigate();
  const setLoggedIn = useSetRecoilState(viewChangerAtom);
  const code = location.search.split("=")[1];
  const loginError = location.search.includes("error");

  const kakaoLoginFn = React.useCallback(async () => {
    const loginResult = await mainAPI.getToken(code, `${redirect!}kakao`);
    const { data } = loginResult;
    cookie.set("token", data.access_token);
    setLoggedIn((prev) => ({ ...prev, isLogedIn: true }));
    navigate("/");
  }, []);

  React.useEffect(() => {
    kakaoLoginFn();
  }, [kakaoLoginFn]);

  if (loginError) {
    alert("로그인을 하셔야 이용이 가능합니다.");
    return navigate("/");
  }

  return <div></div>;
};

export default KakaoSign;
