import React from "react";
import ReactDOM from "react-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useModalControll from "../../hooks/common/useModalControll";
import styled from "styled-components";
import AiReport from "../../pages/reportPage/AiReport";

const ReportModal: React.FC = () => {
  const { closeModal } = useModalControll();

  React.useEffect(() => {
    return () => closeModal("report");
  }, []);

  return (
    <>
      {ReactDOM.createPortal(
        <StReportModal.Container>
          <AiReport />
        </StReportModal.Container>,
        document.querySelector("#root") as HTMLElement
      )}
    </>
  );
};

export default React.memo(ReportModal);
export const StReportModal = {
  Container: styled.div`
    position: fixed;
    z-index: 140;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: scroll;
    background-color: white;
  `,
};
