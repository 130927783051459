import { useNavigate } from "react-router-dom";
import { cookie } from "../../constant/cookies";
import useResetRecoil from "../common/useResetRecoil";
import { useSetRecoilState } from "recoil";
import { viewChangerAtom } from "../../recoil/viewChangerAtom";

/**
 * Logout Handler
 * reset All RecoilValue
 * remove cookie
 */
const useLogout = () => {
  const setLoggedIn = useSetRecoilState(viewChangerAtom);
  const { resetAll } = useResetRecoil();
  const navigate = useNavigate();

  const onLogoutkHandler = () => {
    cookie.remove("token");
    resetAll();
    setLoggedIn((prev) => ({ ...prev, isLogedIn: false }));
    navigate("/");
  };
  return onLogoutkHandler;
};

export default useLogout;
