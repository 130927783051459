import React from "react";
import { useRecoilState } from "recoil";
import { contentAtom } from "../../recoil/contentAtom";
import { mainAPI } from "../../api/axios";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../constant/queryKeys";
import { preLoad } from "../../utils/preLoad";

const useQuestionControl = () => {
  const [question, setQuestion] = useRecoilState(contentAtom);
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [checking, setChecking] = React.useState<boolean>(false);

  // get Question's data :: 문제 데이터 가져오기
  const { refetch } = useQuery(
    [queryKeys.QUESTIONDATA, question.question_no, question.num],
    () => mainAPI.getQuestion(question.question_no, question.num),
    {
      enabled: question.num < 12,
    }
  );
  // preload next question :: 다음 문제 미리 로드하기
  const { refetch: prefetch } = useQuery(
    [queryKeys.QUESTIONDATA, question.question_no, question.num + 1],
    () => mainAPI.getQuestion(question.question_no, question.num + 1),
    {
      onSuccess: (res) => {
        if (res?.data.quizImg) {
          preLoad(res?.data.quizImg);
        }
      },
      enabled: question.num < 11,
    }
  );

  React.useEffect(() => {
    if (question.num < 12) refetch();
    if (question.num + 1 < 12) prefetch();
  }, [fetching]);

  // get user's this content's times :: 몇회차인지 가져오기
  useQuery([queryKeys.TIMES, question.question_no], () => mainAPI.getTimes(question.question_no), {
    onSuccess: (res) => {
      setQuestion((prev) => ({ ...prev, times: Number(res.data.times) }));
    },
    staleTime: 0,
    enabled: checking,
  });

  const setThisQuestionNo = (question_no: number) => {
    setQuestion((prev) => ({ ...prev, question_no }));
  };

  const setThisTimes = async () => {
    setChecking(true);
  };

  /**
   * set next question number
   */
  // setThisQuestion과 분리한 이유 :: preload시 요청을 하고 문제를 사용자에게 제공하기 전에 계속해서 다음문제로 넘어가는 것을 방지하기 위해서.
  const setNextNum = () => {
    setQuestion((prev) => ({ ...prev, num: prev.num + 1 }));
  };

  /**
   * set next render number
   */
  const setNextRenderNum = () => {
    setNextNum();
    setQuestion((prev) => ({ ...prev, renderNum: prev.renderNum + 1 }));
  };

  /**
   * set question content
   * @param question_no content number
   * @param num question number
   */
  const setThisQuestion = (question_no: number) => {
    setThisQuestionNo(question_no);
    setThisTimes();
    setFetching((prev) => !prev);
  };
  return { setThisQuestion, setNextRenderNum, setNextNum };
};

export default useQuestionControl;
