import React from "react";
import { StIntro } from "../../pages/intro/StIntro";
import { KAKAO_URL } from "../../pages/intro/Intro";
import useQuestionControl from "../../hooks/common/useQuestionControl";
import { useNavigate } from "react-router-dom";
import { cookie } from "../../constant/cookies";

const ThumbnailIntro: React.FC<{
  thumbnail: string;
  question_no: number;
  onFull: () => void;
}> = ({ thumbnail, question_no, onFull }) => {
  const { setThisQuestion, setNextNum } = useQuestionControl();
  const navigate = useNavigate();

  const intoTest = async () => {
    if (cookie.get("token")) {
      navigate(`/education`);
      setNextNum();
      onFull();
    } else {
      const response = window.confirm("로그인이 필요한 컨텐츠입니다. 로그인하시겠습니까?");
      if (response) window.location.href = KAKAO_URL;
    }
  };

  return (
    <li>
      <StIntro.Thumb type="button" onClick={intoTest} onMouseEnter={() => setThisQuestion(question_no)}>
        <img src={thumbnail} alt="썸네일" />
      </StIntro.Thumb>
      <StIntro.Subject>모의고사 {question_no}회</StIntro.Subject>
    </li>
  );
};

export default ThumbnailIntro;
