import React from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Intro from "../pages/intro/Intro";
import KakaoSign from "../components/intro/kakao/kakaoSign";
import Education from "../pages/edu/Education";
import MyPage from "../pages/mypage/MyPage";
import { useRecoilValue } from "recoil";
import { viewChangerAtom } from "../recoil/viewChangerAtom";

const Router = () => {
  /**
   * @description Full Screen Handler
   *@example
   * <FullScreen handle={handle}>
   *  <div makeFull={handle} >Full Screen</div>
   * </FullScreen>
   */
  const handle = useFullScreenHandle();
  const { isLogedIn } = useRecoilValue(viewChangerAtom);
  const onFull = () => {
    handle.enter();
  };
  const unFull = () => {
    handle.exit();
  };

  /**Route Access Controller
   * @example
   * <Route path="*" element={<PrivateRoutes user={user} />} />
   */
  const PrivateRoutes = ({ user }: { user: boolean }) => {
    return user ? <Outlet /> : <Navigate to="/" replace />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <FullScreen handle={handle}>
              <Intro onFull={onFull} />
            </FullScreen>
          }
        />
        <Route path="/kakao" element={<KakaoSign />} />
        <Route
          path="/education"
          element={
            <FullScreen handle={handle}>
              <Education unFull={unFull} onFull={onFull} />
            </FullScreen>
          }
        />
        <Route element={<PrivateRoutes user={isLogedIn} />}>
          <Route path="/mypage" element={<MyPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
