import styled, { css, keyframes } from "styled-components";

const loadingText = keyframes`
    0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  `;

const loadingLine = keyframes`
    0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
`;

export const StLoading = {
  Wrap: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    float: left;
    width: 100%;
    height: var(--vh);
    margin: 0 10px 10px 0;
    padding: 20px 20px 20px;
    border-radius: 5px;
    text-align: center;
  `,
  Box: styled.div`
    padding: 16px;
    display: flex;
    gap: 2px;

    /* Safari 14.1 이전 버전 */
    @supports not (inset: 0){
      margin-bottom: 20px;
      :last-child {margin-bottom: 0;}

      >* {margin-right: 2px;}
      >*:last-child {margin-right: 0;}
    }
  `,
  Letter: styled.span<{ numb: string }>`
    animation-name: ${loadingText};
    font-size: 30px;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-direction: linear;
    animation-delay: ${({ numb }) => {
      switch (numb) {
        case "1":
          return "0.6s";
        case "2":
          return "0.72s";
        case "3":
          return "0.84s";
        case "4":
          return "0.96s";
        case "5":
          return "1.08s";
        case "6":
          return "1.2s";
        case "7":
          return "1.32s";
      }
    }};
  `,
  Line: styled.div<{ numb: string }>`
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 15px;
    background-color: #4b9cdb;
    animation: ${({ numb }) => {
      switch (numb) {
        case "1":
          return css`
            ${loadingLine} 0.6s 0.1s linear infinite
          `;
        case "2":
          return css`
            ${loadingLine} 0.6s 0.2s linear infinite
          `;
        case "3":
          return css`
            ${loadingLine} 0.6s 0.3s linear infinite
          `;
      }
    }};
  `,
};
