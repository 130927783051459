import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophoneLines } from "@fortawesome/free-solid-svg-icons";
import { faForwardStep } from "@fortawesome/free-solid-svg-icons";
import { StEduCommon } from "../elements/StEducation";
import useSetType from "../../../hooks/education/useSetType";
import useSTT from "../../../hooks/education/useSTT";
import useElaSolution from "../../../hooks/education/useElaSolution";
import useModalControll from "../../../hooks/common/useModalControll";
import { queryKeys } from "../../../constant/queryKeys";
import { mainAPI } from "../../../api/axios";
import { contentAtom } from "../../../recoil/contentAtom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useQuery } from "@tanstack/react-query";
import { contentInfoAtom } from "../../../recoil/contentInfoAtom";
import AnswerText from "../elements/AnswerText";
import EduSound from "./elements/EduSound";
import StartBtn from "../Btn/StartBtn";

import prepareSound from "../../../assets/audio/prepare-sound.mp3";
import beginSound from "../../../assets/audio/begin-sound.mp3";
import Timer from "../elements/Timer";
import EduHeader from "../header/EduHeader";

const EduSecond: React.FC<{ exitTest: () => void }> = ({ exitTest }) => {
  const setQuestionInfo = useSetRecoilState(contentInfoAtom);
  const { question_no, renderNum } = useRecoilValue(contentAtom);
  const [playPrepare, setPlayPrepare] = React.useState<boolean>(false);
  const [playBegin, setPlayBegin] = React.useState<boolean>(false);
  const [playAnswer, setPlayAnswer] = React.useState<boolean>(false);
  const [timer, setTimer] = React.useState<null | "prepare" | "response" | "start">("start");
  const playTimer = React.useRef<any>(null);

  const { type, goPrepare, goRec, goStart } = useSetType();
  const { answer, startSTTHandler, stopSTTHandler } = useSTT();
  const { recorder, startRecording, stopRecording } = useElaSolution();
  const { openModal } = useModalControll();

  const { data } = useQuery([queryKeys.QUESTIONDATA, question_no, renderNum], () =>
    mainAPI.getQuestion(question_no, renderNum)
  );
  React.useEffect(() => {
    setQuestionInfo(data?.data);
    return () => openModal("comment");
  }, [renderNum]);

  React.useEffect(() => {
    if (type === "prepare") {
      setTimer(null);
      setPlayPrepare(true);
      playTimer.current = setTimeout(() => {
        setTimer("prepare");
        setPlayAnswer(true);
      }, 2000);
      playTimer.current = setTimeout(() => {
        setTimer(null);
        goRec();
      }, (data?.data.time + 2) * 1000);
    } else if (type === "rec") {
      setPlayBegin(true);
      playTimer.current = setTimeout(() => {
        startRecording();
        startSTTHandler();
        setTimer("response");
      }, 2 * 1000);
      playTimer.current = setTimeout(() => {
        setTimer(null);
        stopRecording();
        stopSTTHandler();
        goStart();
      }, (data?.data.time + 2) * 1000);
    } else if (type === "start") {
      setTimer("start");
    }
    return () => {
      setPlayPrepare(false);
      setPlayAnswer(false);
      stopSTTHandler();
      clearTimeout(playTimer.current);
    };
  }, [type]);
  return (
    <StEduCommon.Container>
      <EduHeader exitTest={exitTest} />
      <StEduCommon.Scroll>
        <StEduCommon.QuizImgBox>
          <StEduCommon.QuizImg src={data?.data.quizImg} alt="문제 사진" />
        </StEduCommon.QuizImgBox>
        {timer !== "start" ? (
          <StEduCommon.AnswerTwo>
            <StEduCommon.Mic>
              <FontAwesomeIcon icon={faMicrophoneLines} />
              <span>Answer</span>
            </StEduCommon.Mic>
            <StEduCommon.AnswerText>
              {answer?.answer.map((el, i) => (
                <AnswerText key={`${el}${i}`} text={el.text} score={el.score} />
              ))}
            </StEduCommon.AnswerText>
          </StEduCommon.AnswerTwo>
        ) : (
          <StEduCommon.AnswerTwo />
        )}
        {playBegin ? <EduSound soundUrl={beginSound} stopSound={setPlayBegin} /> : <StEduCommon.EmptyBox />}
        {playPrepare ? <EduSound soundUrl={prepareSound} stopSound={setPlayPrepare} /> : <StEduCommon.EmptyBox />}
        {playAnswer && data?.data.answerUrl ? (
          <EduSound soundUrl={data?.data.answerUrl} stopSound={setPlayAnswer} />
        ) : (
          <StEduCommon.EmptyBox />
        )}
      </StEduCommon.Scroll>
      <StEduCommon.BtnBox>
        {timer === "start" && <StartBtn size="small" onClick={goPrepare} />}
        {timer === "prepare" && <Timer mode="prepare" />}
        {timer === "response" && (
          <>
            <Timer mode="response" />
            <StEduCommon.SkipBtn
              onClick={() => {
                if (recorder?.state === "recording") {
                  setTimer(null);
                  stopRecording();
                  stopSTTHandler();
                  goStart();
                } else {
                  alert("문제를 진행 후에 스킵이 가능합니다.");
                }
              }}
            >
              <span>Skip</span>
              <FontAwesomeIcon icon={faForwardStep} />
            </StEduCommon.SkipBtn>
          </>
        )}
        {timer === null && <div></div>}
      </StEduCommon.BtnBox>
    </StEduCommon.Container>
  );
};

export default EduSecond;

