/**
 * get hour, min, sec from second
 * @param second : second
 * @returns {hour, min, sec}
 */
export const devideTime = (second: number) => {
  const hour: string = String(Math.floor((second / 3600) % 60)).padStart(2, "0");
  const min: string = String(Math.floor((second / 60) % 60)).padStart(2, "0");
  const sec: string = String(Math.floor(second % 60)).padStart(2, "0");
  return { hour, min, sec };
};
