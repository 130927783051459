import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForwardStep } from "@fortawesome/free-solid-svg-icons";

import { useRecoilValue } from "recoil";
import { contentAtom } from "../../../recoil/contentAtom";
import { descriptionSound } from "../../../utils/descriptionSound";
import { StEduCommon } from "./StEducation";
import { descriptionQuestion } from "../../../utils/descriptionQuestion";
import EduHeader from "../header/EduHeader";
import useModalControll from "../../../hooks/common/useModalControll";
import EduSound from "../testType/elements/EduSound";

const EduExplainCom: React.FC<{ exitTest: () => void }> = ({ exitTest }) => {
  const { renderNum } = useRecoilValue(contentAtom);
  const [explainSound, setExplainSound] = React.useState<any>(() => descriptionSound(renderNum));
  const [explainData] = React.useState<any>(() => descriptionQuestion(renderNum));
  const { closeModal } = useModalControll();
  return (
    <StEduCommon.Container color="yellow">
      <EduHeader exitTest={exitTest} />
      <StEduCommon.Scroll>
        <StDescription>
          {explainSound && <EduSound soundUrl={explainSound} stopSound={setExplainSound} />}
          <StTitle>{explainData.title}</StTitle>
          {explainData.explain?.map((el: any, i: number) => (
            <StExplainContent key={`questionType${i + 1}`}>{el}</StExplainContent>
          ))}
        </StDescription>
      </StEduCommon.Scroll>
      <StEduCommon.BtnBox color="yellow">
        <StEduCommon.SkipBtn onClick={() => closeModal("comment")}>
          <span>Skip</span>
          <FontAwesomeIcon icon={faForwardStep} />
        </StEduCommon.SkipBtn>
      </StEduCommon.BtnBox>
    </StEduCommon.Container>
  );
};

export default React.memo(EduExplainCom);

const StDescription = styled.div`
  width: 100%;
  max-width: 1640px;
  height: 100%;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width:1440px){
    padding: 2.5rem 3.75rem;
  }
  
  @media screen and (max-width:480px){
    height: auto;
  }
`;
const StTitle = styled.p`
  width: 100%;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.5;
  color: #0548a4;
  margin-bottom: 24px;

  @media screen and (max-width:480px){
    font-size: 5vw;
  }
`;
const StExplainContent = styled.span`
  width: 100%;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.5;
  color: var(--dark);
  white-space: pre-line;
  
  @media screen and (max-width:480px){
    font-size: 4.375vw;
  }
`;
