import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { contentAtom } from "../../recoil/contentAtom";
import { mainAPI } from "../../api/axios";
import { StReport } from "./StAiReport";
import { makeReportScore, reportDataInit, reportData } from "../../utils/makeReportScore";
import { descriptionLevel } from "../../utils/descriptionLevel";
import bubble from "../../assets/img/bubble.svg";
import Loading from "../loading/Loading";
import DetailScore from "../../components/aiReport/DetailScore";
import ErrorBoundary from "../../helpers/ErrorBoundary";
import useTypeSelector from "../../hooks/education/useTypeSelector";
import { makeDate } from "../../utils/makeDate";
import useModalControll from "../../hooks/common/useModalControll";

const AiReport: React.FC = () => {
  const [reportData, setReportData] = React.useState<reportData>(reportDataInit);
  const [total, setTotal] = React.useState<number>(0);
  const { times, question_no } = useRecoilValue(contentAtom);
  const reportLoad = React.useRef<any>(null);
  const [isLoading, setIsLoading] = React.useState<true | false | null>(false);
  const navigate = useNavigate();
  const { modal, closeModal } = useModalControll();
  const { processHandler } = useTypeSelector();
  // 훅으로 작업 필요  ::
  const { refetch } = useQuery(["report"], () => mainAPI.getReport(question_no, times), {
    onSuccess: (res) => {
      if (res.data.code === 500) {
        setIsLoading(false);
        refetch();
      } else if (res.data.code === 401) {
        alert("로그인이 필요합니다.");
        navigate("/");
      } else {
        const { status, ...data } = res.data;
        const score = makeReportScore(data);
        setReportData((prev) => ({ ...prev, ...score }));
        const adviced = descriptionLevel(score.sum);
        setTotal(score.sum);
        setReportData((prev) => ({ ...prev, email: res.data.email, done_date: res.data.done_date }));
        setReportData((prev) => ({ ...prev, ...adviced }));
        reportLoad.current = setTimeout(() => {
          setIsLoading(true);
        }, 1500);
      }
    },
    staleTime: 0,
  });
  const onClickXHandler = () => {
    if (modal.report === true) {
      closeModal("report");
    } else {
      processHandler.explain();
    }
    setIsLoading(null);
  };
  React.useEffect(() => {
    return () => clearTimeout(reportLoad.current);
  }, []);
  if (isLoading)
    return (
      <StReport.Container>
        <StReport.Close onClick={onClickXHandler}>
          <FontAwesomeIcon icon={faXmark} />
        </StReport.Close>
        <StReport.Top>
          <StReport.Name>응시자: {reportData.email}님</StReport.Name>
          <StReport.Date>응시일: {makeDate(reportData.done_date)}</StReport.Date>
        </StReport.Top>
        <StReport.Title>
          <StReport.SubTxt>실전 모의고사 {question_no}회</StReport.SubTxt>
          <StReport.SubTit>
            <StReport.Bubble>
              <img src={bubble} alt="말풍선" />
              <span>AI</span>
            </StReport.Bubble>
            <p>진단 결과 리포트</p>
          </StReport.SubTit>
        </StReport.Title>
        <StReport.BoxContainer>
          <StReport.BoxArea>
            <StReport.Box type={"false"}>
              <StReport.Level>나의 레벨</StReport.Level>
              <StReport.BarPinBox>
                <StReport.BarContainer>
                  <StReport.Pin score={String(total / 2)}>{reportData.levelName}</StReport.Pin>
                  <StReport.BarValue score={String(total / 2)}>{total}</StReport.BarValue>
                </StReport.BarContainer>
                <StReport.BarLabel>
                  <StReport.Label />
                  <StReport.Label />
                  <StReport.Label />
                  <StReport.Label />
                  <StReport.Label />
                  <StReport.Label />
                  <StReport.LastLabel />
                </StReport.BarLabel>
                <StReport.BarLabelName>
                  <StReport.NameLabel>
                    Novice
                    <br /> Mid/Low
                  </StReport.NameLabel>
                  <StReport.NameLabel>
                    Novice
                    <br /> High
                  </StReport.NameLabel>
                  <StReport.NameLabel>
                    Advanced
                    <br /> Low
                  </StReport.NameLabel>
                  <StReport.NameLabel>
                    Intermediate
                    <br /> Mid
                  </StReport.NameLabel>
                  <StReport.NameLabel>
                    Intermediate
                    <br /> High
                  </StReport.NameLabel>
                  <StReport.NameLabel>
                    Advanced
                    <br /> Low
                  </StReport.NameLabel>
                  <StReport.NameLabel>
                    Advanced
                    <br /> Mid
                  </StReport.NameLabel>
                </StReport.BarLabelName>
                <StReport.BarLabelZero>0</StReport.BarLabelZero>
                <StReport.BarLabelLast>
                  Advanced
                  <br /> High
                  <br />
                  (200)
                </StReport.BarLabelLast>
              </StReport.BarPinBox>
            </StReport.Box>
          </StReport.BoxArea>
          <StReport.BoxArea>
            <StReport.SubTitle>총평</StReport.SubTitle>
            <StReport.Box type={"true"}>
              <StReport.Advice>{reportData.advance}</StReport.Advice>
            </StReport.Box>
          </StReport.BoxArea>
          <StReport.BoxArea>
            <StReport.SubTitle>발음 상세 분석</StReport.SubTitle>
            <StReport.Box type={"true"}>
              <StReport.LegendBox>
                <StReport.LegendMe>
                  <i></i>
                  <span>나의 점수</span>
                </StReport.LegendMe>
                <StReport.LegendAverage>
                  <i></i>
                  <span>평균 점수</span>
                </StReport.LegendAverage>
              </StReport.LegendBox>
              <DetailScore
                title="Accuracy (정확성)"
                myScore={reportData.myScore.avg_accuracy}
                allScore={reportData.allUserScore.avg_accuracy}
              ></DetailScore>
              <DetailScore
                title="Intonation (억양)"
                myScore={reportData.myScore.avg_intonation}
                allScore={reportData.allUserScore.avg_intonation}
              ></DetailScore>
              <DetailScore
                title="Accent (강세)"
                myScore={reportData.myScore.avg_accent}
                allScore={reportData.allUserScore.avg_accent}
              ></DetailScore>
              <DetailScore
                title="Speed (속도)"
                myScore={reportData.myScore.avg_speed}
                allScore={reportData.allUserScore.avg_speed}
              ></DetailScore>
              <DetailScore
                title="Mute, Pause (끊어 읽기)"
                myScore={reportData.myScore.avg_pause}
                allScore={reportData.allUserScore.avg_pause}
              ></DetailScore>
            </StReport.Box>
          </StReport.BoxArea>
        </StReport.BoxContainer>
      </StReport.Container>
    );
  else return <Loading />;
};

export default ErrorBoundary(AiReport);
