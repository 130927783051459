import { atom } from "recoil";
interface Atom {
  explain: boolean;
  volume: boolean;
  comment: boolean;
  isLogedIn: boolean;
  report: boolean;
}
/**
 * @description
 * @example
 * const { modal, openModal, closeModal } = useModalControll();
 * openModal("explain");
 * closeModal("explain");
 */
export const viewChangerAtom = atom<Atom>({
  key: "view",
  default: {
    explain: false,
    volume: false,
    comment: true,
    isLogedIn: false,
    report: false,
  },
});
