import React from "react";
import useDetailScore from "../../hooks/aiReport/useDetailScore";
import { StDetail } from "./StDetail";

export interface detailScore {
  title: string;
  myScore: number;
  allScore?: number;
}

/**
 * get detail description of score
 * @param title : string - title of Level
 * @param myScore : number - my score
 * @param allScore : number - all user score
 */
const DetailScore: React.FC<detailScore> = ({ title, myScore, allScore }) => {
  const advice = useDetailScore({ title, myScore });
  return (
    <StDetail.Container>
      <StDetail.Title>{title}</StDetail.Title>
      <StDetail.Box>
        <StDetail.GraphBox>
          <StDetail.BarContainer>
            <StDetail.BarValue score={String(myScore)}>{myScore}</StDetail.BarValue>
          </StDetail.BarContainer>
          <StDetail.BarContainer>
            <StDetail.BarAllValue score={String(allScore)}>{allScore}</StDetail.BarAllValue>
          </StDetail.BarContainer>
        </StDetail.GraphBox>
        <StDetail.Advice>{advice}</StDetail.Advice>
      </StDetail.Box>
    </StDetail.Container>
  );
};

export default DetailScore;
