import React from "react";
import { StIntro } from "../../pages/intro/StIntro";

import thumbnail1 from "../../assets/img/thumbnail1.jpg";
import thumbnail2 from "../../assets/img/thumbnail2.jpg";
import thumbnail3 from "../../assets/img/thumbnail3.jpg";
import thumbnail4 from "../../assets/img/thumbnail4.jpg";
import thumbnail5 from "../../assets/img/thumbnail5.jpg";
import ThumbnailIntro from "./ThumbnailIntro";

const ContentThumb: React.FC<{ onFull: () => void }> = ({ onFull }) => {
  return (
    <StIntro.ThumbBox>
      <ThumbnailIntro thumbnail={thumbnail1} question_no={1} onFull={onFull} />
      <ThumbnailIntro thumbnail={thumbnail2} question_no={2} onFull={onFull} />
      <ThumbnailIntro thumbnail={thumbnail3} question_no={3} onFull={onFull} />
      <ThumbnailIntro thumbnail={thumbnail4} question_no={4} onFull={onFull} />
      <ThumbnailIntro thumbnail={thumbnail5} question_no={5} onFull={onFull} />
    </StIntro.ThumbBox>
  );
};

export default ContentThumb;
