import React from "react";
import { useRecoilState } from "recoil";
import { volumeAtom } from "../../recoil/volumeAtom";

const useVolumeControl = () => {
  const [volume, setVolume] = useRecoilState(volumeAtom);

  /**-버튼 눌렀을때 */
  const minusClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (volume.volume === 0) {
      return;
    } else {
      setVolume((prev) => ({
        volume: Number((prev.volume - 0.1).toFixed(1)),
      }));
    }
  };

  /**+버튼 눌렀을때 */
  const plusClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (volume.volume === 1) {
      return;
    } else {
      setVolume((prev) => ({
        volume: Number((prev.volume + 0.1).toFixed(1)),
      }));
    }
  };
  return { volume, setVolume, minusClickHandler, plusClickHandler };
};

export default useVolumeControl;
