import React from "react";
import styled from "styled-components";

export interface typeLcs {
  text: string;
  score: number | null;
  high?: boolean;
}

const AnswerText: React.FC<typeLcs> = ({ text, score }) => {
  return <StScoredData score={score}>{`${text} `}</StScoredData>;
};

export default React.memo(AnswerText);

interface scoredData {
  score: number | null;
}
const StScoredData = styled.span<scoredData>`
  position: relative;
  color: ${({ score }) => {
    if (score === null) {
      return "#222";
    } else if (score === 0) {
      return "#222";
    } else if (score <= 0.85) {
      return "#FE5F75";
    } else if (score > 0.85) {
      return "#999";
    } else return "#222";
  }};
  z-index: 20;
`;
