import { atom } from "recoil";

interface Atom {
  conType: number;
  content: string;
  question: string;
  quizUrl: string;
  answerUrl: string;
  quizImg: string;
  answer: string;
  contentUrl: string;
  time: number;
  timestamp: {
    tc_in: string;
    question_detail_no: number;
    tc_out: string;
    word: string;
  }[];
}

export const contentInfoAtom = atom<Atom>({
  key: "questionContent",
  default: {
    conType: 0,
    content: "",
    question: "",
    quizUrl: "",
    answerUrl: "",
    quizImg: "",
    answer: "",
    time: 0,
    contentUrl: "",
    timestamp: [
      {
        tc_in: "",
        question_detail_no: 0,
        tc_out: "",
        word: "",
      },
    ],
  },
});
