import React from "react";
import logo from "../../assets/img/logo_white.svg";
import iconMy from "../../assets/img/icon_my.svg";
import { StIntro } from "./StIntro";
import ContentThumb from "../../components/intro/ContentThumb";
import GuideModal from "../../components/common/GuideModal";
import AiReport from "../reportPage/AiReport";
import { useRecoilValue } from "recoil";
import { eduProcessAtom } from "../../recoil/eduProcessAtom";
import { useNavigate } from "react-router-dom";
import { viewChangerAtom } from "../../recoil/viewChangerAtom";

export const KAKAO_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT}kakao&response_type=code`;

const Intro: React.FC<{ onFull: () => void }> = ({ onFull }) => {
  const { process } = useRecoilValue(eduProcessAtom);
  const navigate = useNavigate();
  const { isLogedIn } = useRecoilValue(viewChangerAtom);
  if (process === "report") return <AiReport />;
  return (
    <StIntro.Container>
      {/* <GuideModal /> */}
      <StIntro.Header>
        <StIntro.HeaderContents>
          <StIntro.Logo>
            <img src={logo} alt="로고" />
          </StIntro.Logo>
          {isLogedIn ? (
            <StIntro.KakaoLogout onClick={() => navigate("mypage")}>마이페이지</StIntro.KakaoLogout>
          ) : (
            <StIntro.KakaoLogin href={KAKAO_URL}>
              <img src={iconMy} alt="카카오 로그인" />
            </StIntro.KakaoLogin>
          )}
        </StIntro.HeaderContents>
      </StIntro.Header>
      <StIntro.Visual>
        <StIntro.VisualContents>
          <StIntro.VisualTitle>
            <strong>AI 평가</strong>로 {"\n"}
            <strong>토익스피킹</strong> 완벽 대비해보세요.
          </StIntro.VisualTitle>
        </StIntro.VisualContents>
      </StIntro.Visual>
      <StIntro.ToeSBox>
        <StIntro.Title>
          <p>영어 AI 스피킹</p>
          <span>AI TOEIC SPEAKING 모의고사</span>
        </StIntro.Title>
        <ContentThumb onFull={onFull} />
      </StIntro.ToeSBox>
      <StIntro.Footer>
        <address>
          <StIntro.Copy>© 2023. DAEKYO Co.LTD. All rights reserved.</StIntro.Copy>
        </address>
      </StIntro.Footer>
    </StIntro.Container>
  );
};

export default Intro;
