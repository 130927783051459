import React from "react";
import Router from "./shares/Router";
import { cookie } from "./constant/cookies";
import { viewChangerAtom } from "./recoil/viewChangerAtom";
import { useSetRecoilState } from "recoil";
import useResetRecoil from "./hooks/common/useResetRecoil";

const App: React.FC = () => {
  const setLoggedIn = useSetRecoilState(viewChangerAtom);
  const { resetAll } = useResetRecoil();
  React.useEffect(() => {
    if (cookie.get("token")) {
      setLoggedIn((prev) => ({ ...prev, isLogedIn: true }));
    }
    return () => resetAll();
  });
  return <Router />;
};

export default App;
