import React from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { eduProcessAtom } from "../../../recoil/eduProcessAtom";
import { contentAtom } from "../../../recoil/contentAtom";
import HeaderBtn from "../Btn/HeaderBtn";
import useModalControll from "../../../hooks/common/useModalControll";
import VolumeController from "../../common/VolumeController";
import useTypeSelector from "../../../hooks/education/useTypeSelector";
import { useNavigate } from "react-router-dom";

const EduHeader: React.FC<{ exitTest: () => void; unFull?: () => void }> = ({ exitTest, unFull }) => {
  const { process } = useRecoilValue(eduProcessAtom);
  const { processHandler } = useTypeSelector();
  const { renderNum } = useRecoilValue(contentAtom);
  const { modal, openModal, closeModal } = useModalControll();
  const navigate = useNavigate();
  const goReport = () => {
    processHandler.report();
    if (unFull) {
      unFull();
      navigate("/");
    }
  };
  return (
    <StHeader>
      <StPart>AI TOEIC SPEAKING</StPart>
      {(process === "test" || process === "result") && <StLogo>Question {renderNum} of 11</StLogo>}
      <StBtnBox>
        <HeaderBtn color={process === "result" ? "blue" : "disabled"} purpose="AI" onClick={goReport} />
        <HeaderBtn color="blue" purpose="CLOSE" onClick={exitTest} />
        <HeaderBtn
          color="blue"
          purpose="VOLUME"
          onClick={modal.volume ? () => closeModal("volume") : () => openModal("volume")}
        />
      </StBtnBox>
      {modal.volume && <VolumeController />}
    </StHeader>
  );
};

export default React.memo(EduHeader);

const StHeader = styled.header`
  width: 100%;
  height: 150px;
  padding: 24px 52px;
  position: relative;
  background: linear-gradient(90deg, #71ceff 0%, #c170f1 100%);
  -webkit-flex-shrink: 0;
  flex-shrink: 0;

  @media screen and (max-width: 1280px) {
    height: 100px;
    padding: 20px 36px;
  }

  @media screen and (max-width: 1200px) {
    padding: 20px 36px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 32px;
  }

  @media screen and (max-width: 480px) {
    height: 20vw;
    padding: 4.15vw 3.33vw;
  }
`;
const StPart = styled.span`
  font-weight: 700;
  font-size: 1.5rem;
  color: #fff;

  @media screen and (max-width:480px){
    font-size: 3.75vw;
  }
`;
const StLogo = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 2.75rem;
  color: #fff;
  text-align: center;

  @media screen and (max-width:480px){
    font-size: 6.875vw;
  }
`;
const StBtnBox = styled.div`
  position: absolute;
  top: 50%;
  right: 52px;
  transform: translateY(-50%);
  display: -webkit-flex;
  display: flex;
  justify-content: end;
  align-items: start;

  @media screen and (max-width: 1280px) {
    right: 36px;
  }

  @media screen and (max-width: 1200px) {
    right: 36px;
  }

  @media screen and (max-width: 768px) {
    right: 32px;
  }

  @media screen and (max-width: 480px) {
    right: 16px;
  }
`;
