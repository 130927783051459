import { atom } from "recoil";
interface Atom {
  process: "explain" | "test" | "result" | "report";
}

export const eduProcessAtom = atom<Atom>({
  key: "process",
  default: {
    process: "explain",
  },
});
