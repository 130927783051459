/**
 * compare user STT result with answer :: return STT result
 * @param str1 : user STT result
 * @param str2 : answer
 */
export const compareWord = (str1: string, str2: string): number[] => {
  const matrix = new Array(str1.length);
  for (let i = 0; i < str1.length; i++) {
    matrix[i] = new Array(str2.length);
  }
  for (let i = 0; i < str1.length; i++) {
    for (let j = 0; j < str2.length; j++) {
      if (str1[i] === str2[j]) {
        if (i === 0 || j === 0) {
          matrix[i][j] = 1;
        } else {
          matrix[i][j] = matrix[i - 1][j - 1] + 1;
        }
      } else {
        if (i === 0) {
          if (j === 0) {
            matrix[i][j] = 0;
          } else {
            matrix[i][j] = matrix[i][j - 1];
          }
        } else if (j === 0) {
          matrix[i][j] = matrix[i - 1][j];
        } else {
          matrix[i][j] = Math.max(matrix[i - 1][j], matrix[i][j - 1]);
        }
      }
    }
  }
  let i = 0,
    j = 0,
    current_lcs_length = 0;
  if (str1.length === 0) {
    i = 0;
    j = 0;
    current_lcs_length = 0;
  } else {
    i = str1.length - 1;
    j = str2.length - 1;
    current_lcs_length = matrix[i][j];
  }

  let lcs_s1 = [],
    lcs_s2 = [];

  while (i !== 0 && j !== 0) {
    if (matrix[i - 1][j] !== current_lcs_length) {
      if (matrix[i][j - 1] !== current_lcs_length) {
        lcs_s1.push(i);
        lcs_s2.push(j);
        i -= 1;
        j -= 1;
        current_lcs_length -= 1;
      } else {
        j -= 1;
      }
    } else {
      i -= 1;
    }
  }

  if (str1.length - 1 !== -1 && str2.length - 1 !== -1) {
    if (matrix[str1.length - 1][str2.length - 1] - lcs_s1.length >= 1) {
      if (i === 0) {
        lcs_s1.push(0);
        lcs_s2.push(str2.indexOf(str1[0]));
      } else {
        lcs_s1.push(str1.indexOf(str2[0]));
        lcs_s2.push(0);
      }
    }
  }

  lcs_s1 = lcs_s1.reverse();
  lcs_s2 = lcs_s2.reverse();
  let lcs_array = new Array(lcs_s1.length);

  for (let i = 0; i < lcs_s1.length; i++) {
    lcs_array.push(str1[lcs_s1[i]]);
  }

  const wdsInit = str2.trim().toLowerCase().split(" ");
  const wds = wdsInit.map((el) => el.trim().replace(/[.,!]/g, ""));
  const scoreList = new Array(wds.length);
  scoreList.fill(1);

  let count = 0;
  for (let i = 0; i < wds.length; i++) {
    let m = count,
      n = m + wds[i].length;
    let word_index = Array.from(Array(n - m).keys())
      .map(function (v) {
        return v + m;
      })
      .map(function (v) {
        return v;
      });

    let score = 0;
    for (let j = 0; j < word_index.length; j++) {
      if (lcs_s2.includes(word_index[j])) {
        score += 1;
      }
    }
    score = score / word_index.length;
    scoreList[i] = score;

    count += wds[i].length + 1;
  }
  return scoreList;
};
