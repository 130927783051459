import styled from "styled-components";

export const StReport = {
  Container: styled.div`
    width: 100%;
    padding-bottom: 140px;
    background-color: white;

    @media screen and (min-width:1441px) and (max-width:1640px){
      padding-left: 140px;
      padding-right: 140px;
    }

    @media screen and (min-width:1201px) and (max-width:1440px){
      padding-left: 105px;
      padding-right: 105px;
    }

    @media screen and (min-width:993px) and (max-width:1200px){
      padding-left: 36px;
      padding-right: 36px;
    }
  `,
  Close: styled.button`
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 36px;
    right: 36px;
    font-size: 2rem;
    color: var(--highlight);
    
    @media screen and (max-width:1280px){
      top: 30px;
    }

    @media screen and (max-width:768px){
      right: 32px;
    }

    @media screen and (max-width:480px){
      top: 16px;
      right: 16px;
    }
  `,
  Top: styled.section`
    max-width: 1640px;
    margin: 0 auto;
    position: absolute;
    z-index: 1;

    @media screen and (max-width:1640px){
      padding-left: 140px;
      padding-right: 140px;
    }

    @media screen and (max-width:1440px){
      padding-left: 105px;
      padding-right: 105px;
    }

    @media screen and (min-width:1281px){
      top: 211px;
    }

    @media screen and (min-width:993px) and (max-width:1280px){
      top: 203px;
    }

    @media screen and (max-width:1200px){
      padding-left: 36px;
      padding-right: 36px;
    }

    @media screen and (min-width:993px){
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: start;
      left: 0;
      right: 0;
    }

    @media screen and (max-width:992px){
      top: 80px;
      right: 0;
    }

    @media screen and (max-width:768px){
      padding-left: 32px;
      padding-right: 32px;
      top: 76px;
    }

    @media screen and (max-width:480px){
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      top: 116px;
      text-align: center;
    }
  `,
  Name: styled.p`
    font-weight: 500;
    font-size: 1.125rem;
    color: #666666;
  `,
  Date: styled.time`
    font-weight: 400;
    font-size: 1.125rem;
    color: #666666;
  `,
  Title: styled.div`
    height: 288px;
    padding: 35px 0;
    background: #e4e8f3;
    font-family: "Gmarket Sans";

    @media screen and (max-width:1280px){
      height: 278px;
      padding: 30px 36px;
    }
    
    @media screen and (min-width:993px){
      width: 488px;
      border-radius: 50%;
      clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%);
      margin: 0 auto;
      margin-top: 100px;
      text-align: center;
    }
    
    @media screen and (max-width:992px){
      height: 260px;
    }

    @media screen and (max-width:768px){
      height: 235px;
      padding: 30px 32px;
    }

    @media screen and (max-width:480px){
      height: 240px;
      padding: 30px 16px;
    }
  `,
  SubTxt: styled.div`
    font-weight: 400;
    font-size: 1.625rem;
    letter-spacing: -0.02em;
    color: #666666;
    margin-bottom: 12px;
    
    @media screen and (max-width:768px){
      font-size: 1.5rem;
    }

    @media screen and (max-width:480px){
      text-align: center;
    }
  `,
  SubTit: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-family: "Gmarket Sans", sans-serif;
    font-weight: 700;
    font-size: 1.75rem;
    letter-spacing: -0.02em;
    color: var(--darkgray);
    
    @media screen and (min-width:993px){
      justify-content: center;
    }
    
    @media screen and (max-width:768px){
      font-size: 1.625rem;
    }

    @media screen and (max-width:480px){
      justify-content: center;
    }
  `,
  Bubble: styled.div`
    position: relative;
    width: 64px;
    margin-right: 20px;

    > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    > span {
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      color: #fff;
    }

    @media screen and (max-width: 1280px) {
      width: 60px;
      margin-right: 16px;
    }

    @media screen and (max-width:768px){
      width: 58px;
    }

    @media screen and (max-width: 480px) {
      width: 54px;
      margin-right: 10px;
    }
  `,
  BoxContainer: styled.div`
    width: 100%;
    max-width: 1640px;
    margin: 0 auto;
    margin-top: -144px;
    position: relative;
    
    @media screen and (max-width:992px){
      width: calc(100% - 72px);
      margin-top: -118px;
    }

    @media screen and (max-width:768px){
      width: calc(100% - 64px);
      margin-top: -92px;
    }

    @media screen and (max-width:480px){
      width: calc(100% - 32px);
      margin-top: -74px;
    }
  `,
  BoxArea: styled.div`
    margin-bottom: 5rem;
    :last-child {
      margin-bottom: 0;
    }
  `,
  Box: styled.div<{ type: string }>`
    padding: ${({ type }) => (type === "true" ? "40px 80px" : "40px 120px 40px 100px")};
    position: relative;
    z-index: 200;
    background-color: #fff;
    border-style: solid;
    border-color: var(--border);
    border-width: ${({ type }) => (type === "true" ? "0px 1px 1px 1px" : "1px")};

    @media screen and (max-width: 1280px){
      padding: ${({ type }) => (type === "true" ? "40px 60px" : "40px 120px 40px 100px")};
    }
        
    @media screen and (max-width:992px){
      padding: ${({ type }) => (type === "true" ? "28px 36px" : "28px 92px 28px 52px")};
    }

    @media screen and (max-width:768px){
      padding: ${({ type }) => (type === "true" ? "28px 32px" : "28px 92px 28px 48px")};
    }

    @media screen and (max-width: 480px) {
      padding: ${({ type }) => (type === "true" ? "14px 16px" : "14px 84px 14px 32px")};
    }
  `,
  Level: styled.p`
    font-weight: 700;
    font-size: 1.25rem;
    color: var(--darkgray);
  `,
  BarPinBox: styled.div`
    width: 100%;
    position: relative;
  `,
  BarContainer: styled.div`
    width: 100%;
    height: 2.5rem;
    position: relative;
    border-radius: 999px;
    background-color: #a8a8a8;
    margin-top: 70px;
  `,
  Pin: styled.div<{ score: string }>`
    width: -moz-max-content;
    width: -webkit-content;
    width: max-content;
    padding: 6px 20px;
    position: absolute;
    top: calc(-100% - 14px);
    left: ${({ score }) => score + "%"};
    transform: translateX(-50%);
    background: var(--highlight);
    border-radius: 6px;
    font-family: "Gmarket Sans";
    font-weight: 500;
    font-size: 1.25rem;
    letter-spacing: -0.02em;
    text-align: center;
    color: #fff;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: var(--highlight);
      border-bottom: 0;
      margin-left: -10px;
      margin-bottom: -10px;
    }
  `,
  BarValue: styled.div<{ score: string }>`
    width: ${({ score }) => score + "%"};
    max-width: 100%;
    height: 2.5rem;
    line-height: 2.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: "Gmarket Sans";
    font-weight: 700;
    font-size: 1.125rem;
    color: #fff;
    background: linear-gradient(90deg, #71ceff 0%, #c170f1 100%);
    padding: 0 30px;
    border-radius: 999px;
  `,
  BarLabel: styled.div`
    width: 98%;
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    
    @media screen and (max-width:768px){
      display: none;
    }
  `,
  LastLabel: styled.div`
    height: 10px;
    width: calc(100% / 7);
  `,
  Label: styled.div`
    height: 10px;
    width: calc(100% / 7);
    border-right: 1px solid var(--highlight);
  `,
  BarLabelName: styled.div`
    width: 98%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    
    @media screen and (max-width:992px){
      font-size: 0.875rem;
    }
    
    @media screen and (max-width:768px){
      display: none;
    }
  `,
  BarLabelZero: styled.div`
    position: absolute;
    top: 20px;
    left: -20px;
    transform: translate(-50%, -50%);
    font-weight: 500;
    text-align: center;
    
    @media screen and (max-width:992px){
      left: -16px;
      font-size: 0.875rem;
    }
    
    @media screen and (max-width:768px){
      top: 50%;
    }
  `,
  BarLabelLast: styled.div`
    position: absolute;
    top: 20px;
    left: calc(100% + 20px);
    transform: translateY(-50%);
    font-weight: 500;
    text-align: center;
    
    @media screen and (max-width:992px){
      left: calc(100% + 16px);
      font-size: 0.875rem;
    }

    @media screen and (max-width:768px){
      top: 50%;
    }
  `,
  NameLabel: styled.div`
    width: calc(100% / 7);
    font-weight: 500;
    text-align: center;
  `,
  SubTitle: styled.p`
    padding: 8px 36px;
    background: #e4e8f3;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #e4e8f3;
    font-weight: 600;
    font-size: 1.25rem;
    color: var(--darkgray);
  `,
  Advice: styled.p`
    font-size: 1.063rem;
    line-height: 180%;
  `,
  LegendBox: styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    margin-bottom: 12px;

    > * {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 6px;
      font-size: 0.875rem;

      /* Safari 14.1 이전 버전 */
      @supports not (inset: 0){
        margin-right: 16px;
        :last-child {margin-right: 0;}
      }

      @media screen and (max-width:480px){
        font-size: 1rem;
      }
    }
  `,
  LegendMe: styled.div`
    > i {
      width: 14px;
      height: 14px;
      display: inline-block;
      background-color: #71ceff;

      /* Safari 14.1 이전 버전 */
      @supports not (inset: 0){
        margin-right: 6px;
      }

      @media screen and (max-width:480px){
        width: 12px;
        height: 12px;
      }
    }
  `,
  LegendAverage: styled.div`
    > i {
      width: 14px;
      height: 14px;
      display: inline-block;
      background-color: #c170f1;
      
      /* Safari 14.1 이전 버전 */
      @supports not (inset: 0){
        margin-right: 6px;
      }

      @media screen and (max-width:480px){
        width: 12px;
        height: 12px;
      }
    }
  `,
};