import React from "react";
import Loading from "../pages/loading/Loading";

/**
 * Catch Error
 * @param ProtectedComponent : React.ComponentType
 * @returns
 */
const ErrorBoundary = (ProtectedComponent: React.ComponentType) => {
  return class Boundary extends React.Component {
    state = {
      hasError: false,
    };
    static getDerivedStateFromError(error: Error) {
      return { hasError: true };
    }
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
      this.setState({
        hasError: true,
      });
    }
    render(): React.ReactNode {
      const { hasError } = this.state;
      if (hasError) {
        return <Loading />;
      } else {
        return <ProtectedComponent />;
      }
    }
  };
};

export default ErrorBoundary;
