import styled from "styled-components";

export const StEduCommon = {
  Container: styled.div<{ color?: "yellow" }>`
    height: var(--vh);
    padding-bottom: 10rem;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: ${({ color }) =>
      color === "yellow" ? "#F4EDD3" : "white"};

    /* 세로 모드 : Portrait(포트레이트) 모드 */
    @media (orientation: portrait) {
      transform: rotate(90deg);
      transform-origin: top right;
      position: absolute;
      top: 100%;
      right: 0;
      width: var(--vh);
      height: 100vw;
    }
    
    @media screen and (max-width:480px){
      padding-bottom: 8rem;
    }
  `,
  Scroll: styled.div`
    width: 100%;
    max-width: 1640px;
    height: 100%;
    margin: 0 auto;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 17px;
      height: 17px;
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      width: 17px;
      background-color: #ddd;
      border-radius: 999px;
      background-clip: padding-box;
      border: 5px solid transparent;
    }
    ::-webkit-scrollbar-track {
      width: 17px;
      border-radius: 999px;
      background-color: transparent;
    }
  `,
  BtnBox: styled.div<{ color?: "yellow" }>`
    position: absolute;
    width: 100%;
    height: 10rem;
    right: 0;
    bottom: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    background-color: ${({ color }) => (color === "yellow" ? "#F4EDD3" : "white")};
        
    @media screen and (max-width:480px){
      height: 8rem;
    }
  `,
  SkipBtn: styled.button`
    padding: 8px 16px;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    font-size: 1.75rem;
    color: var(--dark);
    cursor: pointer;

    > svg,
    > i {
      font-size: 1.25rem;
    }

    /* Safari 14.1 이전 버전 */
    @supports not (inset: 0){
      >span {margin-right: 12px;}
    }
      
    @media screen and (max-width:480px){
      font-size: 4.375vw;
    }
  `,
  SkipBtn2: styled.button` //문제 prepare/response 스킵 버튼
    padding: 8px 16px;
    position: absolute;
    top: 50%;
    right: 36px;
    transform: translateY(-50%);
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    font-size: 1.75rem;
    color: var(--gray);
    cursor: pointer;

    > svg,
    > i {
      font-size: 1.25rem;
    }

    /* Safari 14.1 이전 버전 */
    @supports not (inset: 0){
      >span {margin-right: 12px;}
    }

    @media screen and (max-width:768px){
      right: 32px;
    }

    @media screen and (max-width:480px){
      right: 16px;
      font-size: 4.375vw;
    }
  `,
  EmptyBox: styled.div`
    position: absolute;
    z-index: -100;
  `,
  QuizImgBox: styled.div`
    width: 100%;
    padding: 2.5rem 3.75rem;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 1.5;
    color: var(--dark);
    white-space: pre-line;
          
    @media screen and (max-width:480px){
      font-size: 4.375vw;
    }
  `,
  QuizImg: styled.img`
    display: block;
    height: 37.03703703704vh;
    max-height: 400px;
    object-fit: contain;
    margin: 0 auto;
  `,
  Content: styled.p`
    padding: 2.5rem 3.75rem;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 1.5;
    color: var(--dark);
    white-space: pre-line;
              
    @media screen and (max-width:480px){
      font-size: 4.375vw;
    }
  `,
  Question: styled.p`
    padding: 1.25rem 3.75rem;
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 1.5;
    color: var(--dark);
    white-space: pre-line;
              
    @media screen and (max-width:480px){
      font-size: 4.375vw;
    }
  `,
  AnswerOne: styled.div`
    width: 100%;
    padding: 2.5rem 3.75rem;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 1.5;
    color: var(--dark);
    white-space: pre-line;
              
    @media screen and (max-width:480px){
      font-size: 4.375vw;
    }
  `,
  AnswerTwo: styled.div`
    padding: 1.25rem 3.75rem;
    font-weight: 400;
    font-size: 1.75rem;
    line-height: 1.5;
    color: var(--dark);
    position: relative;
              
    @media screen and (max-width:480px){
      font-size: 4.375vw;
    }
  `,
  AnswerText: styled.div`
    white-space: pre-line;
    text-indent: 170.2px;

    @media screen and (max-width:992px){
      text-indent: 114.047px;
    }

    @media screen and (max-width:480px){
      text-indent: 28.5vw;
    }
  `,
  Mic: styled.div`
    padding: 5px 22px;
    position: absolute;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: var(--lilac);
    border-radius: 999px;
    color: #ffffff;
    margin-top: calc(((1.75rem*1.5) - (1.125rem*1.5 + 10px))/2);
    margin-right: 24px;
    line-height: 1;

    @media screen and (max-width:480px){
      padding: 1vw 4.5vw;
      margin-top: calc(((4.375vw*1.5) - (2.8vw*1.5 + 10px))/2);
      margin-right: 16px;
    }

    > span {
      font-family: "Gmarket Sans";
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 1.5;
      letter-spacing: -0.5px;
      margin-top: 1px;

      /* Safari 14.1 이전 버전 */
      @supports not (inset: 0){
        margin-left: 8px;
      }
      
      @media screen and (max-width:480px){
        font-size: 2.8vw;
      }
    }
  `,
};
export const StEduResult = {
  Scroll: styled.div`
    width: 100%;
    max-width: 1640px;
    height: 100%;
    margin: 0 auto;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 17px;
      height: 17px;
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      width: 17px;
      background-color: #ddd;
      border-radius: 999px;
      background-clip: padding-box;
      border: 5px solid transparent;
    }
    ::-webkit-scrollbar-track {
      width: 17px;
      border-radius: 999px;
      background-color: transparent;
    }
  `,
  Description: styled.div`
    max-width: 1640px;
    height: 100%;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  `,
  Title: styled.p`
    width: 100%;
    font-weight: 700;
    font-size: 3.25rem;
    line-height: 1.5;
    color: #0548a4;
    margin-bottom: 24px;
    
    @media screen and (max-width:480px){
      font-size: 8.125vw;
    }
  `,
  ExplainContent: styled.span`
    width: 100%;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 1.5;
    color: var(--dark);
    white-space: pre-line;
              
    @media screen and (max-width:480px){
      font-size: 4.375vw;
    }
  `,
};
