import React from "react";
import Tbody from "./elements/Tbody";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import bubble from "../../assets/img/bubble.svg";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../constant/queryKeys";
import { mainAPI } from "../../api/axios";
import ReportModal from "../../components/common/ReportModal";
import ErrorBoundary from "../../helpers/ErrorBoundary";
import useModalControll from "../../hooks/common/useModalControll";
import useLogout from "../../hooks/intro/useLogout";
import { useNavigate } from "react-router-dom";

const MyPage: React.FC = () => {
  const logoutHandler = useLogout();
  const { modal } = useModalControll();
  const { data: report } = useQuery([queryKeys.MYPAGE], mainAPI.getMyReport);
  const navigate = useNavigate();
  return (
    <StMyPage.Container>
      {modal.report && <ReportModal />}
      <StMyPage.Header>
        <StMyPage.HomeBtn onClick={() => navigate("/")}>
          <FontAwesomeIcon icon={faHouse} />
        </StMyPage.HomeBtn>
        <StMyPage.Logout onClick={logoutHandler}>로그아웃</StMyPage.Logout>
        <StMyPage.Title>
          <StMyPage.Bubble>
            <img src={bubble} alt="말풍선" />
            <span>AI</span>
          </StMyPage.Bubble>
          <span>진단 리포트 목록</span>
        </StMyPage.Title>
      </StMyPage.Header>
      <StMyPage.Table>
        <li className="th">모의고사 회차</li>
        <li className="th">응시일</li>
        <li className="th">점수</li>
        <li className="th">리포트 보기</li>
        
        {report?.data.map((el: any, i: number) => (
          <Tbody data={el} key={`tbody${i}`} />
        ))}
      </StMyPage.Table>
    </StMyPage.Container>
  );
};

export default ErrorBoundary(MyPage);

const StMyPage = {
  Container: styled.div`
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  `,
  Header: styled.div`
    width: 100%;
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width:1440px){
      padding-left: 15px; padding-right: 15px;
    }

    @media screen and (max-width:1200px){
      padding-left: 36px; padding-right: 36px;
    }

    @media screen and (max-width:768px){
      padding-left: 32px; padding-right: 32px;
    }

    @media screen and (max-width:480px){
      padding-left: 16px; padding-right: 16px;
    }
  `,
  HomeBtn: styled.div`
    width: 48px; height: 48px; display: -webkit-flex; display: flex; justify-content: center; align-items: center; background: #fff; border: 1px solid var(--highlight); border-radius: 50%; cursor: pointer;

    @media screen and (max-width:992px){
      width: 42px; height: 42px;
    }
        
    @media screen and (max-width:480px){
      width: 36px; height: 36px;
    }

    i, svg {font-size: 0.875rem; color: var(--highlight);}
  `,
  Title: styled.div`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-family: "Gmarket Sans", sans-serif;
    font-weight: 700;
    font-size: 1.75rem;
    letter-spacing: -0.02em;
    color: var(--darkgray);
    margin-top: 5.125rem;
    
    @media screen and (min-width:993px){
      justify-content: center;
    }

    @media screen and (max-width:992px){
      margin-top: 4rem;
    }
    
    @media screen and (max-width:768px){
      font-size: 1.625rem;
    }

    @media screen and (max-width:480px){
      justify-content: center;
    }
  `,
  Bubble: styled.div`
    position: relative;
    width: 64px;
    margin-right: 20px;

    > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    > span {
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      color: #fff;
    }

    @media screen and (max-width: 1280px) {
      width: 60px;
      margin-right: 16px;
    }

    @media screen and (max-width:768px){
      width: 58px;
    }

    @media screen and (max-width: 480px) {
      width: 54px;
      margin-right: 10px;
    }
  `,
  Logout: styled.button`
    min-width: 5.625rem; //min-width로 수정
    height: 2.75rem;
    padding: 0 1rem;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid var(--gray);
    background-color: transparent;
    font-weight: 600;
    font-size: 0.938rem;
    color: var(--gray);
    letter-spacing: -0.1px;
  `,
  Table: styled.ul`
    width: 100%; display: grid; grid-template-columns: repeat(4, 1fr); padding-top: 4.75rem;
        
    @media screen and (max-width:992px){
      padding-top: 3rem;
    }

    > li {
      text-align: center; display: -webkit-flex; display: flex; justify-content: center; align-items: center;

      @media screen and (min-width:993px){
        padding: 13px 0.5rem;
      }

      @media screen and (min-width:481px) and (max-width:992px){
        padding: 11px 0.5rem;
      }

      @media screen and (max-width:480px){
        padding: 5px 16px;
      }
    }

    > .th {background: #E4E8F3; font-weight: 700;}
    
    > .td {
      border-right: 1px solid var(--border); border-bottom: 1px solid var(--border); word-break: normal;

      :last-child, :nth-child(4n) {border-right: 0;}
    }
  `
};