import React from "react";
import { StEduCommon, StEduResult } from "./StEducation";
import EduHeader from "../header/EduHeader";

const EduResult: React.FC<{ exitTest: () => void; unFull: () => void }> = ({ exitTest, unFull }) => {
  return (
    <StEduCommon.Container color="yellow">
      <EduHeader exitTest={exitTest} unFull={unFull} />
      <StEduResult.Scroll>
        <StEduResult.Description>
          <StEduResult.Title>This is the end of the speaking test.</StEduResult.Title>
          <StEduResult.ExplainContent>
            AI Report 버튼을 누르시면 성적표를 확인하실 수 있습니다.
          </StEduResult.ExplainContent>
        </StEduResult.Description>
      </StEduResult.Scroll>
    </StEduCommon.Container>
  );
};

export default EduResult;
