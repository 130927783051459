interface data {
  avg_part: number[];
  sum_all: {
    avg_accent: number;
    avg_accuracy: number;
    avg_intonation: number;
    avg_speed: number;
    avg_pause: number;
    total_count: number;
  };
  sum_me: {
    accent: number;
    accuracy: number;
    pause: number;
    intonation: number;
    speed: number;
  };
}

export interface reportData {
  sum: number;
  allUserScore: {
    avg_accent: number;
    avg_accuracy: number;
    avg_intonation: number;
    avg_pause: number;
    avg_speed: number;
  };
  myScore: {
    avg_accent: number;
    avg_accuracy: number;
    avg_intonation: number;
    avg_pause: number;
    avg_speed: number;
  };
  levelName: string;
  advance: string;
  done_date: Date;
  email: string;
}

export const reportDataInit = {
  sum: 0,
  allUserScore: {
    avg_accent: 0,
    avg_accuracy: 0,
    avg_intonation: 0,
    avg_pause: 0,
    avg_speed: 0,
  },
  myScore: {
    avg_accent: 0,
    avg_accuracy: 0,
    avg_pause: 0,
    avg_intonation: 0,
    avg_speed: 0,
  },
  levelName: "",
  advance: "",
  done_date: new Date(),
  email: "",
};

/**
 * get report score data
 * @param data : report data
 * @returns
 */
export const makeReportScore = (data: data) => {
  const { avg_part, sum_all, sum_me } = data;
  const total = [avg_part[0], avg_part[1], avg_part[2], avg_part[3], avg_part[4]];

  const sum: number = Math.round(total.reduce((acc, cur) => acc + cur, 0) / 11) * 2;
  const { total_count, ...allUserData } = sum_all;

  const allUserScore = {
    avg_accent: Math.round(allUserData.avg_accent / total_count),
    avg_accuracy: Math.round(allUserData.avg_accuracy / total_count),
    avg_intonation: Math.round(allUserData.avg_intonation / total_count),
    avg_speed: Math.round(allUserData.avg_speed / total_count),
    avg_pause: Math.round(allUserData.avg_pause / total_count),
  };
  const myScore = {
    avg_accent: Math.round(sum_me.accent / 11),
    avg_accuracy: Math.round(sum_me.accuracy / 11),
    avg_intonation: Math.round(sum_me.intonation / 11),
    avg_speed: Math.round(sum_me.speed / 11),
    avg_pause: Math.round(sum_me.pause / 11),
  };
  return { sum, allUserScore, myScore };
};
