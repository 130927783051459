import styled from "styled-components";

export const StDetail = {
  Container: styled.div`
    width: 100%;
    margin-bottom: 1.5rem;
    :last-child {margin-bottom: 0;}
  `,
  Title: styled.p`
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 12px;
  `,
  Box: styled.div`
    display: flex;
    align-items: start;
    width: 100%;
    
    @media screen and (max-width:768px){
      flex-direction: column;
    }
  `,
  GraphBox: styled.div`
    width: 30%;
    
    @media screen and (max-width:768px){
      width: 100%;
    }
  `,
  Advice: styled.div`
    width: 70%;
    padding: 0 20px;
    font-size: 1.063rem;
        
    @media screen and (max-width:768px){
      width: 100%;
      padding: 16px 0;
    }
  `,
  BarContainer: styled.div`
    width: 100%;
    height: 20px;
    background-color: #a8a8a8;
    border-radius: 999px;
    overflow: hidden;
    margin-bottom: 10px;
    :last-child {margin-bottom: 0px;}
  `,
  BarValue: styled.div<{ score: string }>`
    height: 20px;
    width: ${({ score }) => {
      return score + "%";
    }};
    border-radius: 999px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    background-color: #71ceff;
    font-weight: 500;
    color: #fff;
  `,
  BarAllValue: styled.div<{ score: string }>`
    height: 20px;
    width: ${({ score }) => {
      return score + "%";
    }};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    border-radius: 999px;
    background-color: #c170f1;
    font-weight: 500;
    color: #fff;
  `,
};
