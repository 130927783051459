import { useResetRecoilState, useSetRecoilState } from "recoil";
import { recorderAtom } from "../../recoil/recorderAtom";
import { contentAtom } from "../../recoil/contentAtom";
import { contentInfoAtom } from "../../recoil/contentInfoAtom";
import { volumeAtom } from "../../recoil/volumeAtom";
import { answerAtom } from "../../recoil/answerAtom";
import { eduProcessAtom } from "../../recoil/eduProcessAtom";
import { viewChangerAtom } from "../../recoil/viewChangerAtom";

/**
 * reset RecoilValue
 */
const useResetRecoil = () => {
  const resetVolume = useResetRecoilState(volumeAtom);
  const resetContent = useResetRecoilState(contentAtom);
  const setContent = useSetRecoilState(contentAtom);
  const resetContentInfo = useResetRecoilState(contentInfoAtom);
  const resetAnswer = useResetRecoilState(answerAtom);
  const resetProcess = useResetRecoilState(eduProcessAtom);
  const resetView = useResetRecoilState(viewChangerAtom);
  const resetRecorder = useResetRecoilState(recorderAtom);

  const resetEducation = () => {
    setContent((prev) => ({ ...prev, renderNum: 1, num: 1 }));
    resetContentInfo();
    resetVolume();
    resetAnswer();
  };
  const resetAll = () => {
    resetEducation();
    resetRecorder();
    resetContent();
    resetProcess();
    resetView();
  };
  return { resetEducation, resetAll };
};

export default useResetRecoil;
