import React from "react";
import { detailScore } from "../../components/aiReport/DetailScore";
import { makeDetailExplain } from "../../utils/makeDetailReportScore";

/**
 * get advice from my score
 * @param title : title of Level
 * @param myScore : my score
 * @returns
 */
const useDetailScore = ({ title, myScore }: detailScore) => {
  const [advice, setAdvice] = React.useState<string>("");
  React.useEffect(() => {
    switch (title) {
      case "Accuracy (정확성)":
        const detailExplainAccuracy = makeDetailExplain.makeAccuracy(myScore);
        setAdvice(detailExplainAccuracy);
        break;
      case "Intonation (억양)":
        const detailExplainInotonation = makeDetailExplain.makeintonation(myScore);
        setAdvice(detailExplainInotonation);
        break;
      case "Accent (강세)":
        const detailExplainAccent = makeDetailExplain.makeAccent(myScore);
        setAdvice(detailExplainAccent);
        break;
      case "Speed (속도)":
        const detailExplainSpeed = makeDetailExplain.makeSpeed(myScore);
        setAdvice(detailExplainSpeed);
        break;
      case "Mute, Pause (끊어 읽기)":
        const detailExplainPause = makeDetailExplain.makePause(myScore);
        setAdvice(detailExplainPause);
        break;
      default:
        break;
    }
  }, [title, myScore]);
  return advice;
};

export default useDetailScore;
