import React from "react";
import useQuestionControl from "../common/useQuestionControl";

/**
 * Inner Content Type
 * @returns {type, goPrepare, goRec, goStart}
 * @description
 * type: "start" | "prepare" | "rec"
 */
const useSetType = () => {
  const [type, setType] = React.useState<"start" | "prepare" | "rec">("start");
  const { setNextRenderNum } = useQuestionControl();
  const goPrepare = () => setType("prepare");
  const goRec = () => setType("rec");
  const goStart = () => {
    setType("start");
    setNextRenderNum();
  };
  const goNextprepare = () => {
    setType("prepare");
    setNextRenderNum();
  };

  return { type, goPrepare, goRec, goStart, goNextprepare };
};

export default useSetType;
