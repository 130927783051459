import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { contentInfoAtom } from "../../recoil/contentInfoAtom";
import { answerAtom } from "../../recoil/answerAtom";
import { useSpeechRecognition } from "react-speech-recognition";
import { compareWord } from "../../utils/compareWord";
import SpeechRecognition from "react-speech-recognition";

const useSTT = () => {
  const data = useRecoilValue(contentInfoAtom);

  const [answer, setAnswer] = useRecoilState(answerAtom);
  const [startSTT, setStartSTT] = React.useState<boolean>(false);
  const [comparedWord, setComparedWord] = React.useState<string>("");
  const [init, setInit] = React.useState<number>(0);
  const compareInterval = React.useRef<any>(null);
  const flowTime = React.useRef<number>(0);

  const { transcript, interimTranscript, resetTranscript } = useSpeechRecognition();

  const splitWord = (word: string) => {
    return word.split(" ");
  };
  const setAnswerList = (word: any) => {
    setAnswer({ answer: [] });
    flowTime.current = 0;
    const text = splitWord(word);
    for (let i = 0; i < text.length; i++) {
      const splitedText = {
        text: text[i],
        score: null,
      };
      setAnswer((prev: any) => ({
        answer: [...prev.answer, { ...splitedText }],
      }));
    }
  };

  // 말할때 구현되도록 추가한 작업
  const whenToTalk = () => {
    if (
      interimTranscript.split(" ")[interimTranscript.split(" ").length - 1] ===
      transcript.split(" ")[transcript.split(" ").length - 1]
    ) {
      flowTime.current += 0.01;
    }
  };

  React.useEffect(() => {
    if (
      interimTranscript.split(" ")[interimTranscript.split(" ").length - 1] ===
      transcript.split(" ")[transcript.split(" ").length - 1]
    ) {
      whenToTalk();
    }
  }, [init]);
  React.useEffect(() => {
    setAnswer({ answer: [] });
    if (transcript !== "") {
      setStartSTT(true);
      if (compareInterval.current === null) {
        compareInterval.current = setInterval(() => {
          setInit((prev) => (prev += 0.01));
        }, 10);
      }
    }
    if (startSTT) {
      setComparedWord("");
      for (let i = 0; i < data.timestamp.length; i++) {
        if (flowTime.current >= +data?.timestamp[i].tc_out) {
          setComparedWord((prev) => {
            if (prev !== "") {
              return prev + " " + data.timestamp[i].word;
            } else if (prev === "") {
              return data.timestamp[i].word;
            } else return prev;
          });
        }
      }
    }
    const scoreValue = compareWord(transcript.toLowerCase().trim(), comparedWord.toLowerCase().trim());
    const splitedAnswer = splitWord(data.answer);
    for (let i = 0; i < splitedAnswer.length; i++) {
      const splitedText = {
        text: splitedAnswer[i],
        score: scoreValue[i] ?? null,
      };
      setAnswer((prev: any) => ({
        answer: [...prev.answer, { ...splitedText }],
      }));
    }
  }, [transcript]);

  const startSTTHandler = () => {
    SpeechRecognition.startListening({ continuous: true, language: "en-US" });
  };
  const stopSTTHandler = () => {
    SpeechRecognition.stopListening();
    resetTranscript();
    clearInterval(compareInterval.current);
    compareInterval.current = null;
    flowTime.current = 0;
  };

  React.useEffect(() => {
    setAnswerList(data?.answer);
  }, [data]);

  return { answer, startSTTHandler, stopSTTHandler };
};

export default useSTT;
