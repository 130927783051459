import React from "react";
import { StLoading } from "./StLoading";

const Loading: React.FC = () => {
  return (
    <>
      <StLoading.Wrap>
        <StLoading.Box>
          <StLoading.Line numb="1" />
          <StLoading.Line numb="2" />
          <StLoading.Line numb="3" />
        </StLoading.Box>
        <StLoading.Box>
          <StLoading.Letter numb="1">로</StLoading.Letter>
          <StLoading.Letter numb="2">딩</StLoading.Letter>
          <StLoading.Letter numb="3">중</StLoading.Letter>
          <StLoading.Letter numb="4">입</StLoading.Letter>
          <StLoading.Letter numb="5">니</StLoading.Letter>
          <StLoading.Letter numb="6">다</StLoading.Letter>
          <StLoading.Letter numb="7">.</StLoading.Letter>
        </StLoading.Box>
      </StLoading.Wrap>
    </>
  );
};

export default Loading;
