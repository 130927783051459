import React from "react";
import styled from "styled-components";
import StartBtn from "../Btn/StartBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import useTypeSelector from "../../../hooks/education/useTypeSelector";

const EduExplain: React.FC<{ exitTest: () => void }> = ({ exitTest }) => {
  const { processHandler } = useTypeSelector();
  return (
    <StEducation.Container color="yellow">
      <StEducation.Awsome onClick={exitTest}>
        <FontAwesomeIcon icon={faXmark} />
      </StEducation.Awsome>
      <StEducation.Title>AI TOEIC SPEAKING 모의고사</StEducation.Title>
      <StEducation.SubExplain>
        <StEducation.SubTitle>꼭 확인해주세요!</StEducation.SubTitle>
        <StEducation.ExplainContent>
          정확한 음성인식을 위해 조용한 공간에서 진행해주세요! <br />
          정확한 테스트를 위해 마이크를 이용해주세요! <br />
          PC에서 진행 시, 크롬(Chrome)브라우저를 권장합니다. <br />
          에어팟과 같은 블루투스 이어폰 사용 시 테스트 진행이 원활하지 않을 수 있습니다. (일반 이어폰 권장) <br />
          모든 학습을 완료하셔야 AI리포트를 받아보실 수 있습니다.
        </StEducation.ExplainContent>
      </StEducation.SubExplain>
      <StEducation.BtnBox>
        <StartBtn size="big" onClick={processHandler.test} />
      </StEducation.BtnBox>
    </StEducation.Container>
  );
};

export default EduExplain;

const StEducation = {
  Container: styled.div`
    height: var(--vh);
    padding-bottom: 10rem;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: ${({ color }) =>
      color === "yellow" ? "#F4EDD3" : "white"};

    /* 세로 모드 : Portrait(포트레이트) 모드 */
    @media (orientation: portrait) {
      transform: rotate(90deg);
      transform-origin: top right;
      position: absolute;
      top: 100%;
      right: 0;
      width: var(--vh);
      height: 100vw;
    }

    @media screen and (max-width:480px){
      padding-bottom: 8rem;
    }
  `,
  Title: styled.p`
    padding: 3.75rem 3.25rem;
    font-weight: 700;
    font-size: 4rem;
    color: var(--dark);
    text-align: center;

    @media screen and (max-width:640px){
      padding: 2.5rem 3.25rem;
    }
    
    @media screen and (max-width:480px){
      font-size: 10vw;
    }
  `,
  SubExplain: styled.div`
    width: 100%;
    max-width: 1640px;
    margin: 0 auto;
    padding: 3.75rem 5rem;
    background: #f5f5f5;

    @media screen and (max-width: 1440px) {
      width: calc(100% - 30px);
    }

    @media screen and (max-width: 1200px) {
      width: calc(100% - 72px);
    }

    @media screen and (max-width: 768px) {
      width: calc(100% - 64px);
    }
    
    @media screen and (min-width:641px){
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 20px;
    }

    @media screen and (max-width: 640px){
      width: 100%;
      height: calc(100vw - 4rem*1.2 - 2.5rem*2 - 10rem);
      overflow-y: auto;
      
      ::-webkit-scrollbar {
        width: 17px;
        height: 17px;
        background-color: transparent;
      }
      ::-webkit-scrollbar-thumb {
        width: 17px;
        background-color: #ddd;
        border-radius: 999px;
        background-clip: padding-box;
        border: 5px solid transparent;
      }
      ::-webkit-scrollbar-track {
        width: 17px;
        border-radius: 999px;
        background-color: transparent;
      }
    }

    @media screen and (max-width:480px){
      height: calc(100vw - 10vw*1.2 - 2.5rem*2 - 8rem);
    }
  `,
  SubTitle: styled.p`
    font-weight: 700;
    font-size: 1.5rem;
    color: var(--darkgray);
    margin-bottom: 12px;

    @media screen and (max-width:480px){
      font-size: 3.75vw;
    }
  `,
  ExplainContent: styled.div`
    font-weight: 300;
    font-size: 1.5rem;
    color: var(--primary);
    white-space: pre-line;
    line-height: 150%;
    
    @media screen and (max-width:480px){
      font-size: 3.75vw;
    }
  `,
  Awsome: styled.button`
    width: 3.125rem;
    height: 3.125rem;
    position: absolute;
    top: 36px;
    right: 36px;
    font-size: 2.75rem;

    @media screen and (max-width:768px){
      top: 32px;
      right: 32px;
    }

    @media screen and (max-width:480px){
      top: 16px;
      right: 16px;
    }
  `,
  BtnBox: styled.div`
    width: 100%;
    height: 10rem;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    
    @media screen and (max-width:480px){
      height: 8rem;
    }
  `,
};