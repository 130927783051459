import { useSetRecoilState } from "recoil";
import { eduProcessAtom } from "../../recoil/eduProcessAtom";

const useTypeSelector = () => {
  const setProcess = useSetRecoilState(eduProcessAtom);
  const processHandler = {
    explain: () => setProcess((prev) => ({ ...prev, process: "explain" })),
    test: () => setProcess((prev) => ({ ...prev, process: "test" })),
    result: () => setProcess((prev) => ({ ...prev, process: "result" })),
    report: () => setProcess((prev) => ({ ...prev, process: "report" })),
  };
  return { processHandler };
};

export default useTypeSelector;
