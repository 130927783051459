import { useRecoilState } from "recoil";
import { viewChangerAtom } from "../../recoil/viewChangerAtom";

const useModalControll = () => {
  const [modal, modalControl] = useRecoilState(viewChangerAtom);
  const openModal = (type: string) => {
    modalControl((prev) => ({
      ...prev,
      [type]: true,
    }));
  };
  const closeModal = (type: string) => {
    modalControl((prev) => ({
      ...prev,
      [type]: false,
    }));
  };
  return { modal, openModal, closeModal };
};

export default useModalControll;
