import React from "react";
import styled, { css, keyframes } from "styled-components";
import { contentInfoAtom } from "../../../recoil/contentInfoAtom";
import { useRecoilValue } from "recoil";
import { devideTime } from "../../../utils/devideTime";

interface TimerProps {
  mode: "prepare" | "response";
  readyTime?: number | undefined;
}

const Timer: React.FC<TimerProps> = ({ mode, readyTime }) => {
  const { time } = useRecoilValue(contentInfoAtom);
  const [second, setSecond] = React.useState<number>(() => readyTime ?? time);
  const { hour, min, sec } = devideTime(second);
  const timerRef = React.useRef<number>(0);

  React.useEffect(() => {
    timerRef.current = window.setInterval(() => {
      setSecond((prev) => {
        if (prev >= 1) {
          return prev - 1;
        } else {
          return prev;
        }
      });
    }, 1000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  return (
    <StContainer>
      <StTitleBox>
        <StRec mode={mode}>REC</StRec>
        <StTitle>{mode === "prepare" ? "Preparation Time" : "Response Time"}</StTitle>
      </StTitleBox>
      <StTime>
        {hour}:{min}:{sec}
      </StTime>
    </StContainer>
  );
};

export default Timer;

const StContainer = styled.div`
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StTitleBox = styled.div`
  width: 300px;
  height: 40px;
  padding: 4px 0px;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background: var(--dark);
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: var(--dark);
  border-radius: 4px 4px 0px 0px;
  color: #ffffff;
  
  @media screen and (max-width:480px){
    width: 62.5vw;
    height: 8.33vw;
  }
`;
const recording = keyframes`
  0% {
    box-shadow: 0;
  }

  5% {
    box-shadow: 0px 0px 1px rgba(255, 0, 0, 0.3), 0px 0px 3px rgba(220, 0, 0, 0.7), 0px 0px 5px rgba(255, 0, 0, 0.3);
  }

  65% {
    box-shadow: 0px 0px 1px 2px rgba(255, 0, 0, 0.3), 0px 0px 3px 3px rgba(220, 0, 0, 0.7), 0px 0px 5px 8px rgba(255, 0, 0, 0.3);
  }

  90% {
    box-shadow: 0;
  }
`;
const StRec = styled.div<{ mode: "prepare" | "response" }>`
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 1rem;
  color: #ffffff;
  text-align: center;
  animation: ${({ mode }) => (mode === "response" ? css`1.5s linear infinite ${recording}` : "")};
  background-color: ${({ mode }) => {
    switch (mode) {
      case "prepare":
        return "#999999";
      case "response":
        return "#FF0000";
    }
  }};

  /* Safari 14.1 이전 버전 */
  @supports not (inset: 0){
    margin-right: 12px;
  }
    
  @media screen and (max-width:480px){
    font-size: 2.5vw;
  }
`;
const StTitle = styled.span`
  font-weight: 700;
  font-size: 1.5rem;
  color: #fff;
    
  @media screen and (max-width:480px){
    font-size: 3.75vw;
  }
`;
const StTime = styled.span`
  width: 300px;
  height: 40px;
  padding: 4px 0px;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: var(--dark);
  border-radius: 0px 0px 4px 4px;
  font-family: "Oxanium";
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--dark);
  text-align: center;
    
  @media screen and (max-width:480px){
    width: 62.5vw;
    height: 8.33vw;
    font-size: 3.75vw;
  }
`;
