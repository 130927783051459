import React from "react";
import { makeDate } from "../../../utils/makeDate";
import useModalControll from "../../../hooks/common/useModalControll";
import { useSetRecoilState } from "recoil";
import { contentAtom } from "../../../recoil/contentAtom";

export interface TbodyProps {
  data: { question_no: number; times: number; done_date: Date; score: number };
}

const Tbody: React.FC<TbodyProps> = ({ data: { done_date, question_no, score, times } }) => {
  const { openModal } = useModalControll();
  const setReport = useSetRecoilState(contentAtom);
  const onClickHandler = () => {
    setReport((prev) => ({ ...prev, question_no, times }));
    openModal("report");
  };
  return (
    <>
    <li className="td">{`모의고사 ${question_no}회`}</li>
    <li className="td">{makeDate(done_date)}</li>
    <li className="td">{Math.round(score * 2)}</li>
    <li className="td"><button onClick={onClickHandler}>AI Report</button></li>
    </>
  );
};

export default Tbody;
