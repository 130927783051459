import React from "react";
import ReactHowler from "react-howler";
import { useRecoilValue } from "recoil";
import { volumeAtom } from "../../../../recoil/volumeAtom";
import { StEduCommon } from "../../elements/StEducation";
const EduSound: React.FC<{
  soundUrl: string;
  stopSound: (value: boolean) => void;
}> = ({ soundUrl, stopSound }) => {
  const { volume } = useRecoilValue(volumeAtom);
  return (
    <StEduCommon.EmptyBox>
      <ReactHowler
        volume={volume}
        src={soundUrl}
        playing={true}
        onEnd={() => stopSound(false)}
      />
    </StEduCommon.EmptyBox>
  );
};

export default EduSound;
