/**
 * get description question by content type
 * @param contentType : content type
 * @returns
 */
export const descriptionQuestion = (contentType: number) => {
  switch (contentType) {
    case 1:
      return {
        title: "Questions 1~2 : Read a text aloud",
        explain: [
          "Directions : In this part of the test, you will read aloud the text on the screen.",
          "You will have 45 seconds to prepare.",
          "Then you will have 45 seconds to read the text aloud.",
        ],
      };
    case 3:
      return {
        title: "Questions 3~4 : Describe a picture",
        explain: [
          "Directions : In this part of the test, you will describe the picture on your screen in as much detail as you can.",
          "You will have 45 seconds to prepare your response.",
          "Then you will have 30 seconds to speak about the picture.",
        ],
      };
    case 5:
      return {
        title: "Questions 5~7 : Respond to questions",
        explain: [
          "Directions : In this part of the test, you will answer three questions.",
          "You will have three seconds to prepare after you hear each question.",
          "You will have 15 seconds to respond to questions 5 and 6, and 30 seconds to respond to question 7.",
        ],
      };
    case 8:
      return {
        title: "Questions 8~10 : Respond to Questions Using Information Provided",
        explain: [
          "Directions : In this part of the test, you will answer three questions based on the information provided.",
          "You will have 45 seconds to read the information before the questions begin.",
          "You will have three seconds to prepare and 15 seconds to respond to Questions 8 and 9.",
          "You will hear Question 10 two times. ",
          "You will have three seconds to prepare and 30 seconds to respond to Question 10",
        ],
      };
    case 11:
      return {
        title: "Question 11 : Express an Opinion",
        explain: [
          "Directions : In this part of the test, you will give your opinion about a specific topic.",
          "Be sure to say as much as you can in the time allowed.",
          "You will have 30 seconds to prepare. Then you will have 60 seconds to speak.",
        ],
      };
  }
};
