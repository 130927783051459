import { atom } from "recoil";
interface Atom {
  volume: number;
}
export const volumeAtom = atom<Atom>({
  key: "setting",
  default: {
    volume: 1,
  },
});
