import close from "../assets/img/btn-close.png";
import close2x from "../assets/img/btn-close@2x.png";
import volume from "../assets/img/btn-volume.png";
import volume2x from "../assets/img/btn-volume@2x.png";
import docs from "../assets/img/btn-report.png";
import docs2x from "../assets/img/btn-report@2x.png";

import { preLoad } from "../utils/preLoad";
export const imgPreloadAll = () => {
  preLoad(close);
  preLoad(close2x);
  preLoad(volume);
  preLoad(volume2x);
  preLoad(docs);
  preLoad(docs2x);
};
// 작업:: 아이콘에 대한 작업 필요
