/**
 * get description sound url
 * @param title : title of content
 */
export const descriptionSound = (renderNum: number) => {
  switch (renderNum) {
    case 1:
      return "https://vanvo.s3.ap-northeast-2.amazonaws.com/part1/part1.mp3";
    case 3:
      return "https://vanvo.s3.ap-northeast-2.amazonaws.com/part2/part2.mp3";
    case 5:
      return "https://vanvo.s3.ap-northeast-2.amazonaws.com/part3/part3.mp3";
    case 8:
      return "https://vanvo.s3.ap-northeast-2.amazonaws.com/part4/part4.mp3";
    case 11:
      return "https://vanvo.s3.ap-northeast-2.amazonaws.com/part5/part5.mp3";
  }
};
