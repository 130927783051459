import React from "react";

import { useSetRecoilState } from "recoil";
import { recorderAtom } from "../../recoil/recorderAtom";

/**get MediaRecorder Func::
 * 1. get audio from user
 * 2. set recorderAtom
 * 3. return recorderAtom
 */
export const useGetAudio = (): void => {
  const setRecorder = useSetRecoilState(recorderAtom);
  const getAudio = async () => {
    await navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: false,
      })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        setRecorder(() => ({ recorder }));
      });
  };
  React.useEffect(() => {
    getAudio();
  }, []);
};
