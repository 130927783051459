import axios from "axios";
import { cookie } from "../constant/cookies";

export const client = axios.create({
  baseURL: process.env.REACT_APP_MAIN_SERVER,
  withCredentials: true,
});

client.interceptors.request.use((config) => {
  config.headers.Authorization = `${cookie.get("token")}`;
  return config;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response.status === 401) {
      cookie.remove("token");
      return 401;
    }
  }
);

export const mainAPI = {
  /**
   * get Question's data
   * @param question_no : Content number
   * @param num : question number
   */
  getQuestion: (question_no: number, num: number) => client.post("/api/edu", { question_no, num }),

  /**
   * get ela solution's score
   * @param data :{user_no:number, question_no:number, times:number, num:number, student_audio:file}
   */
  getAnswer: (data: FormData) => client.post("/api/ans", data),

  /**
   * get user's data(kakao login)
   * @param code : kakao login code
   * @param redirect : kakao login redirect uri
   */
  getToken: async (code: string, redirect: string) => {
    return await client.get(`/kakao/gettoken?code=${code}&redirect_uri=${redirect}`);
  },

  /**
   * get user's this content's times
   */
  getTimes: (question_no: number) => client.post("/api/check", { question_no }),

  /**
   * get Report's data
   * @param user_no : user number
   * @param question_no : Content number
   * @param times :content's times
   */
  getReport: (question_no: number, times: number) => client.post("/api/report", { question_no, times }),
  getMyReport: () => client.get("/api/myreport"),
};
