import React from "react";
import styled from "styled-components";

interface StartBtnProps {
  size: "big" | "small";
  onClick: () => void;
}

/**
 * Start button component in education
 * @param size : big, small
 * @param onClick : onClick event
 */
const StartBtn: React.FC<StartBtnProps> = ({ size, onClick }) => {
  return (
    <StBtn size={size} onClick={onClick}>
      START
    </StBtn>
  );
};

export default React.memo(StartBtn);

const StBtn = styled.button<StartBtnProps>`
  width: ${({ size }) => {
    switch (size) {
      case "big":
        return "100%";
      case "small":
        return "auto";
    }
  }};
  height: ${({ size }) => {
    switch (size) {
      case "big":
        return "100%";
      case "small":
        return "auto";
    }
  }};
  font-size: ${({ size }) => {
    switch (size) {
      case "big":
        return "4rem";
      case "small":
        return "2.875rem";
    }
  }};
  padding: ${({ size }) => (size === "small" ? "12px 80px" : "0")};
  border-radius: ${({ size }) => (size === "small" ? "16px" : "none")};
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0) 14.58%, rgba(255, 255, 255, 0.35) 100%),
    linear-gradient(90.43deg, #52c0e5 19.3%, #4183dd 89.48%);
  font-weight: 800;
  color: #fff;
  letter-spacing: -2px;
  cursor: pointer;
  user-select: none;

  
  @media screen and (max-width:480px){
    font-size: ${({ size }) => {
      switch (size) {
        case "big":
          return "10vw";
        case "small":
          return "7.18vw";
      }
    }};
  }
`;
