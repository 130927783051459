import React from "react";
import Loading from "../loading/Loading";
import EduExplain from "../../components/education/elements/EduExplain";
import EduExplainCom from "../../components/education/elements/EduComment";
import useModalControll from "../../hooks/common/useModalControll";
import EduFirst from "../../components/education/testType/EduFirst";
import EduSecond from "../../components/education/testType/EduSecond";
import EduThird from "../../components/education/testType/EduThird";
import EduFourth from "../../components/education/testType/EduFourth";
import EduFifth from "../../components/education/testType/EduFifth";
import EduResult from "../../components/education/elements/EduResult";
import { useRecoilValue } from "recoil";
import { eduProcessAtom } from "../../recoil/eduProcessAtom";
import { contentAtom } from "../../recoil/contentAtom";
import { useNavigate } from "react-router-dom";
import { useGetAudio } from "../../hooks/education/useGetAudio";
import { imgPreloadAll } from "../../helpers/imgPreloadAll";
import useResetRecoil from "../../hooks/common/useResetRecoil";

const Education: React.FC<{ unFull: () => void; onFull: () => void }> = ({ unFull, onFull }) => {
  const navigate = useNavigate();
  const { process } = useRecoilValue(eduProcessAtom);
  const { renderNum } = useRecoilValue(contentAtom);
  const { modal } = useModalControll();
  const { resetEducation } = useResetRecoil();
  useGetAudio();
  const backMain = () => {
    navigate("/");
  };
  const backTest = () => {
    onFull();
  };
  const exitTest = () => {
    if (process !== "result") {
      const result = window.confirm("테스트를 종료하시겠습니까?");
      if (result) {
        backMain();
      } else {
        backTest();
      }
    } else {
      unFull();
      backMain();
    }
  };
  React.useEffect(() => {
    imgPreloadAll();
    return () => resetEducation();
  }, []);

  if (process === "explain") return <EduExplain exitTest={exitTest} />;
  if (process === "test") {
    switch (renderNum) {
      case 1:
        if (modal.comment) return <EduExplainCom exitTest={exitTest} />;
        else return <EduFirst exitTest={exitTest} />;
      case 2:
        return <EduFirst exitTest={exitTest} />;
      case 3:
        if (modal.comment) return <EduExplainCom exitTest={exitTest} />;
        else return <EduSecond exitTest={exitTest} />;
      case 4:
        return <EduSecond exitTest={exitTest} />;
      case 5:
        if (modal.comment) return <EduExplainCom exitTest={exitTest} />;
        else return <EduThird exitTest={exitTest} />;
      case 6:
        return <EduThird exitTest={exitTest} />;
      case 7:
        return <EduThird exitTest={exitTest} />;
      case 8:
        if (modal.comment) return <EduExplainCom exitTest={exitTest} />;
        else return <EduFourth exitTest={exitTest} />;
      case 9:
        return <EduFourth exitTest={exitTest} />;
      case 10:
        return <EduFourth exitTest={exitTest} />;
      case 11:
        if (modal.comment) return <EduExplainCom exitTest={exitTest} />;
        else return <EduFifth exitTest={exitTest} />;
      default:
        return <Loading />;
    }
  }
  if (process === "result") return <EduResult exitTest={exitTest} unFull={unFull} />;
  return <Loading />;
};

export default Education;

