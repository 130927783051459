import React from "react";
import styled from "styled-components";
import close from "../../../assets/img/btn-close.png";
import close2x from "../../../assets/img/btn-close@2x.png";
import volume from "../../../assets/img/btn-volume.png";
import volume2x from "../../../assets/img/btn-volume@2x.png";
import docs from "../../../assets/img/btn-report.png";
import docs2x from "../../../assets/img/btn-report@2x.png";

interface ButtonProps {
  color: "blue" | "disabled" | "grey";
  purpose: "CLOSE" | "VOLUME" | "AI";
  onClick: any;
}

/**
 * Header button component
 * @param color : blue, disabled, grey
 * @param purpose : CLOSE, VOLUME, AI
 * @param onClick : onClick event
 */
const HeaderBtn: React.FC<ButtonProps> = ({ color, purpose, onClick }) => {
  const purposeElement = (): JSX.Element | undefined => {
    switch (purpose) {
      case "CLOSE":
        return <img src={close} srcSet={`${close} 1x, ${close2x} 2x`} alt="close" />;
      case "VOLUME":
        return <img src={volume} srcSet={`${volume} 1x, ${volume2x} 2x`} alt="volume" />;
      case "AI":
        return <img src={docs} srcSet={`${docs} 1x, ${docs2x} 2x`} alt="AI Report" />;
    }
  };

  return (
    <StButton color={color} purpose={purpose} onClick={() => onClick()} disabled={color === "disabled"}>
      {purposeElement()}
    </StButton>
  );
};

export default React.memo(HeaderBtn);

const StButton = styled.button<ButtonProps>`
  position: relative;
  opacity: ${({ color }) => (color === "disabled" ? 0.5 : 1)};
  transition: opacity 0.3s;
  cursor: ${({ color }) => (color === "disabled" ? "default" : "pointer")};
  user-select: none;

  @media screen and (max-width:1280px){
    margin-left: -12px;
  }

  @media screen and (max-width:480px){
    margin-left: -2.5vw;
  }
  
  >img {
    object-fit: contain; display: block;

    @media screen and (max-width:1280px){
      max-width: 100px; height: 80px;
    }

    @media screen and (max-width:480px){
      max-width: 20.8vw; height: 16vw;
    }
  }
`;
