/**
 * @description: descriptionLevel
 * @param {number} level : user's level
 * @return {string} levelName : user's level name
 * @return {string} advance : user's level description
 */
export const descriptionLevel = (level: number) => {
  let levelName;
  let advance;
  if (level === 200) {
    levelName = "Advanced High";
    advance =
      "일상적인 직장 생활에서 사용하는 어휘와 표현을 실제로 말하는데 익숙하며, 영어로 대화를 이어가는데 큰 불편함을 느끼지 않습니다. 자신의 생각을 길게 말하거나 복잡한 질문에 대답할 때도 간단하고 명확한 표현을 사용할 줄 압니다. 문법과 어휘력도 좋아서 복잡한 이야기도 쉽게 풀어 설명할 수 있습니다. 주어진 질문에 답하고 핵심을 설명하기 위해 구어체를 사용하기도 합니다. 원어민에 버금가는 발음, 억양, 강세 또한 돋보입니다. 더 이상 가르칠 게 없습니다!";
  } else if (level >= 180 && 199 >= level) {
    levelName = "Advanced Mid";
    advance =
      "일상적인 직장 생활에서 사용하는 어휘와 표현을 실제로 말하는데 익숙하며, 영어로 대화를 이어가는데 큰 불편함을 느끼지 않습니다. 자신의 생각을 말하거나 복잡한 질문에 대답할 때도 간단명료하게 표현합니다. 주어진 질문에 답하고 핵심을 설명하기 위해 구어체를 사용하기도 합니다. 다만, 발음, 억양, 강세 등에서 사소한 실수가 있고, 복잡한 문장을 말할 때는 속도가 느려지거나 어순이 꼬일 수 있습니다. 너무 조급해 하지 말고 의식적인 끊어 읽기를 통해 말의 리듬감을 살려주면 자연히 실수도 줄어듭니다.";
  } else if (level >= 160 && 179 >= level) {
    levelName = "Advanced Low";
    advance =
      "일상적인 직장 생활에서 사용하는 어휘와 표현을 실제로 말할 수 있지만 아직 복잡한 대화는 익숙하지 않습니다. 자신의 생각을 간단명료하게 표현하고 핵심을 전달하는데 부족함은 없습니다. 주어진 질문에 답하고 핵심을 설명하기 위해 구어체를 사용하기도 합니다. 다만 발음, 억양, 강세 등에서 사소한 실수가 있고, 깊이 있는 대화를 나누기에는 문법을 더 다듬어야 합니다. 큰 목소리로 정확한 발음과 억양을 훈련할 수 있는 프레젠테이션 연습을 추천합니다. 업무 계획/일정을 동료에게 자세히 설명하는 것도 좋은 훈련이 됩니다.";
  } else if (level >= 140 && 159 >= level) {
    levelName = "Intermediate High";
    advance =
      "일상적인 직장 생활에서 사용하는 어휘와 표현을 알고 있지만 암기한것 외에 창조적인 대화는 아직 부족합니다. 하지만 자신의 생각을 간단하게 표현하고 핵심을 전달하는데 부족함은 없습니다. 적절한 어휘 선택과 문법 학습이 뒷받침되면 더 복잡한 이야기도 나눌 수 있습니다. 발음, 억양, 강세 등에서 실수가 많은 이유도 문법에 확신이 없어서 망설이기 때문입니다. 문장을 연결해주는 접속사와 전치사 학습이 다채로운 표현으로 긴 대화를 이끌어가는데 큰 도움이 될 겁니다.";
  } else if (level >= 110 && 139 >= level) {
    levelName = "Intermediate Mid";
    advance =
      "일상적인 직장 생활에서 사용하는 어휘와 표현 학습에 시간을 더 투자해야 합니다. 자신의 생각을 간단하게 말 하고 핵심을 전달할 수는 있지만, 표현력에 한계가 있어 다양성이 부족합니다. 동의어와 유의어 학습이 뒷받침되면 더 다양한 표현을 습득할 수 있습니다. 발음, 억양, 강세 등에서 실수가 많은 이유도 응용에 필요한 어휘력이 부족하여 망설이기 때문입니다. 아직은 긴 문장보다 짧은 문장을 여러 번 연습하는 것이 효율적입니다. 문장을 끊어 읽으며 음절에 따라 정확히 발음하는 어휘 복습도 잊지 마세요!";
  } else if (level >= 90 && 109 >= level) {
    levelName = "Intermediate Low";
    advance =
      "일상적인 직장 생활에서 사용하는 어휘와 표현 학습에 시간을 투자해야 합니다. 자신의 생각을 간단하게 말하고 핵심을 전달할 수는 있지만, 표현력에 한계가 있어 다양성이 부족합니다. 동의어와 유의어 학습이 뒷받침되면 더 다양한 표현을 습득할 수 있습니다. 발음, 억양, 강세 등에서 실수가 많은 이유도 응용에 필요한 어휘력이 부족하여 망설이기 때문입니다. 아직은 긴 문장보다 짧은 문장을 여러 번 연습하는 것이 효율적입니다. 문장을 끊어 읽으며 음절에 따라 정확히 발음하는 어휘 복습도 잊지 마세요! ";
  } else if (level >= 60 && 89 >= level) {
    levelName = "Novice High";
    advance =
      "자신의 생각을 간단하게 표현할 수 있지만, 정확한 의미 전달은 힘들 수도 있습니다. 어휘력 부족으로 복잡한 질문이나 요청에 답변하는 것은 한계가 있습니다. 문법 학습을 병행해서 자신 있게 말할 수 있는 문장 구조를 만들어가야 합니다. 아직 발음, 억양, 강세 등을 신경쓰기 보다는 실수가 많아도 일단 올바른 문장을 만들고 그것을 말해보는 연습이 중요합니다. 긴 문장보다 짧은 문장을 여러 번 연습하는 것이 훨씬 효율적입니다. 문장을 끊어 읽으며 음절에 따라 정확히 나누어 발음하는 연습도 잊지 마세요!";
  } else if (level >= 0 && 59 >= level) {
    levelName = "Novice Mid/Low";
    advance =
      "자신의 생각을 간단한 어휘 몇 개로 표현할 수 있지만, 올바른 문장 구조나 어순을 기대하기는 힘듭니다. 어휘와 문법 학습은 스피킹의 왕도로 가는 지름길입니다. 문법 학습으로 자신 있게 말할 수 있는 문장 구조를 만들고, 어휘력을 늘려 구조 속에 응용하는 연습이 필수입니다. 아직 발음, 억양, 강세 등을 신경쓰기 보다는 실수가 많아도 일단 올바른 문장을 만들고 그것을 말해보는 연습이 중요합니다. 긴 문장보다 짧은 문장을 여러 번 연습하는 것이 훨씬 효율적입니다. 문장을 끊어 읽으며 음절에 따라 정확히 나누어 발음하는 연습도 잊지 마세요!";
  } else {
    levelName = "";
    advance = "";
  }
  return { levelName, advance };
};
